/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import "@angular/localize/init";
/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 */
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags.ts';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import "zone.js/dist/zone";
import { loadTranslations } from "@angular/localize"; // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

var translationEl = {
  locale: "ar",
  translations: {
    landingPageH1: "منصة كاملة لإدارة سفر الأعمال. التوفير فوري",
    landingPageUnderH1:
      "منصة سحابية بتقنية حديثة تعمل على تبسيط وأتمتة سفر موظفين الشركات وتشغيله في دقائق.",
    getStartBtn: " ابدأ بالتسجيل",
    landingRequestBtn: "اطلب عرض",
    landingSec1Title: "توفير فوري",
    landingSec1ItemA: "بدون عمولات",
    landingSec1ItemB: "بدون رسوم حجز",
    landingSec1Desc:
      "تقدم سفرهب SafarHub حلول فعالة من حيث التكلفة لإدارة السفر للشركات. على عكس أنظمة إدارة السفر الأخرى ، ستوفر شركتك من اليوم الأول.",
    landingCalLink: "احسب مدخراتك",
    chooseAnswer: "اختر الاجابة",
    landingSec2Title: "كل شيء في مكان واحد",
    landingSec2Desc:
      "أداة واحدة لتبسيط جميع جوانب سفر الشركات. نحن نساعد الشركات على الحجز وإدارة سياسات السفر وأتمتة الموافقات وتقليل إيداع النفقات وعرض التقارير والمزيد.",
    landingSec3Title: "معدلات اعتماد لا مثيل لها",
    landingSec3Desc:
      "يتيح محرك الحجز السريع والسهل للمسافرين حجز الفنادق ورحلات الطيران في دقائق! مع برنامج مكافآت سفرهب، يكون لدى المسافرين حافز لاتخاذ خيارات لتوفير المال.",
    landingFreeJoin: "انضم مجانا",
    landingSec4Title: "أتمتة عملية الموافقة على السفر وإدارة المصروفات",
    landingSec4Desc:
      "يتكامل سفرهب مع الأدوات الرائدة  في مجال إدارة النفقات وأدوات الاتصال مثل Expensify و Slack. بنقرة واحدة ، يمكن للمسافرين تحميل نفقات السفر في أداة إدارة نفقات الشركة أو إدارة الموافقة على السفر مباشرة من هذه الادوات المستخدمة من الشركة.",
    moreInfo: "بحاجة الى مزيد من المعلومات؟ تحدث إلى خبيرنا",
    pricing: "الأسعار",
    pricingDesc:
      "نعتقد أن الوصول إلى جميع الميزات يعطي أكبر قيمة لعملك. لهذا السبب نقوم ببناء نموذج تسعير بسيط للغاية يناسب جميع الشركات بغض النظر عن عدد الموظفين أو حجم السفر.",
    priceStarter: "أساسية",
    priceStarter1: "١-٥ أعضاء",
    priceStarter2: "٢٩،٩٩ دولارًا رسومًا ثابتة شهريًا",
    priceProf: "المحترفين",
    priceProf1: "أكثر من ٥ أعضاء",
    priceProf2: "٨ دولارات للعضو في الشهر",
    freeTrialTitle: "ابدأ تجربتك المجانية",
    freeTrial1: "٣٠ يوما تجريبية مجانية",
    freeTrial2: "إضافة عدد غير محدود من الأعضاء",
    freeTrial3: "شامل جميع المميزات ",
    freeTrial4: "لا نطلب منك ادخال معلومات الدفع",
    faq: "الأسئلة الشائعة",
    Q1: "ما هو SafarHub؟",
    Q2: "ما هي الميزات التي يقدمها SafarHub؟",
    Q3: "ما هو متوسط التوفير الذي ستحصل عليه الشركات من خلال الانضمام إلى SafarHub؟",
    Q4: "هل يوجد حد معين لموظفي الشركة للانضمام؟",
    Q5: "ما هي منتجات وخدمات السفر التي تقدمها SafarHub؟",
    Q6: "هل يتكامل نظام SafarHub مع أنظمة أخرى؟",
    Q7: "هل يمكنني تقديم مكافآت لموظفي الذين يوفرون نفقات سفر العمل؟",
    Q8: "ما هو نموذج التسعير للانضمام إلى SafarHub؟",
    Q9: "هل أتلقى تأكيدًا فوريًا بمجرد إجراء الحجز؟",
    A1: "يوفر SafarHub منصة سفر شاملة للشركات تساعدهم على تقليل تكاليف السفر بنسبة ۲٥ – ۳٥% بالإضافة إلى تحسين إنتاجية الموظفين.",
    A2: "بالإضافة الى محرك الحجز، يوفر SafarHub نظام لإدارة سياسة السفر ومحرك لنظام الموافقة، وإدارة الفريق والأدوار، وادارة إيداع النفقات، وتقارير الإنفاق على السفر، ووحدة مكافآت الموظفين، والمزيد.",
    A3: "من خلال الجمع بين تطبيق سياسة السفر وأفضل أسعار السفر لدينا ، ستتمكن الشركات من توفير ۲٥ – ۳٥% على سفر الشركات.",
    A4: "لا توجد حدود دنيا أو قصوى. يمكن لأي شركة صغيرة أو متوسطة الحجم أو مؤسسة الانضمام إلى المنصة في بضع دقائق.",
    A5: "الفنادق والرحلات الجوية. يوفر SafarHub الوصول إلى أكثر من ٢٠٠ ألف فندق حول العالم وجميع شركات الطيران الرئيسية بما في ذلك شركات الطيران منخفضة التكلفة. سوف تقدم SafarHub تأجير السيارات والسكك الحديدية في الأشهر القليلة المقبلة.",
    A6: "نعم. يتكامل SafarHub بسلاسة مع نظام إدارة النفقات Expensify لأتمتة النفقات. في الوقت نفسه، سيتمكن المستخدمون من الموافقة على رحلات العمل وإدارتها مباشرةً من Slack.",
    A7: "نعم. يمكن للشركات وضع برنامج مكافآت على النحو الأمثل حيث يحصل الموظفون على جزء من المدخرات عندما يحجزون سفرًا بسعر أقل من سياسة السفر الخاصة بالشركة. سيتمكن الموظفون من استرداد مكافآتهم ببطاقات الهدايا الرقمية مثل Amazon.",
    A8: "نموذجنا بسيط، سيتمكن العملاء من الوصول إلى جميع الميزات، بدون رسوم خفية أو رسوم حجز. يعتمد نموذج التسعير الخاص بنا على عدد الأعضاء المنضمين. يرجى التحقق من قسم التسعير أعلاه.",
    A9: "نعم، يقوم محرك الحجز لدينا بتأكيد حجزك في بضع ثوانٍ ويرسل إليك رسالة تأكيد بالبريد الإلكتروني على الفور.",
    requestDemo1: " نحن هنا لمساعدتك ، أدخل اسمك و بريد العمل الإلكتروني",
    requestDemo2:
      " من خلال إرسال بريدك الإلكتروني إلينا ، فإنك توافق على تلقي رسائل البريد الإلكتروني الخاصة بنا.",
    noThanks: "لا، شكرا",
    requestDemo3:
      "لقد تلقينا طلبك ، وسيتصل بك أحد فريقنا لتحديد موعد لجلسة تجريبية.",
    saveCal1: "حاسبة التوفير",
    saveCal2:
      " احسب متوسط المدخرات السنوية على نفقات السفر من خلال الإجابة على  {$INTERPOLATION} أسئلة فقط. ",
    calFinal: "بناءً على مدخلاتك ، ستكون مدخراتك عند الانضمام إلى SafarHub بين",
    resetBtn: "ابدأ من جديد",
    calStartBtn: "ابدأ الآن",
    signIn1: "سجّل الدخول إلى شركتك",
    signIn2:
      "أدخل عنوان بريد العمل الإلكتروني للوصول إلى مساحة العمل الخاصة بك.",
    notMember: "لست عضواً؟ ابدأ بالتسجيل",
    signUp1: "أدخل بريد العمل الالكتروني",
    signUp2:
      "سجل مجانا باستخدام بريد عملك الإلكتروني. غير مسموح بعناوين البريد الإلكتروني الشخصية أو التجريبية.",
    inviteSignUp1: " قم بتسجيل حساب في {$INTERPOLATION}",
    inviteSignUp2: "أدخل اسمك و كلمة مرور للتسجيل",
    inviteSignUp3: "أدخل اسمك الصحيح حيث لا يمكن تغييره لاحقا",
    invalidInvitation: "هذه الدعوة لم تعد صالحة",
    nameNote: "لتغيير اسمك ، يرجى الاتصال بمسؤول النظام.",
    passwordNote:
      "يتم تشفير بياناتك ومشاركتها مع شركتك وموردي خدمات السفر فقط عند حجز رحلة عملك.",
    personalInfo: "البيانات الشخصية",
    ForgotPassword1: "سنرسل إليك تعليمات بالبريد",
    ForgotPassword: "تغيير كلمة المرور",
    workspaceLogin1: " سجّل الدخول إلى {$INTERPOLATION}",
    workspaceLogin2: "أدخل كلمة المرور للدخول إلى حسابك",
    passwordPlaceHolder: "كلمة المرور",
    forgotPassword: "هل نسيت كلمة المرور؟",
    requiredPasswordError: "كلمة المرور مطلوبة",
    verifySnackBarMsg: "تم إرسال رسالة التحقق إلى {$INTERPOLATION} بنجاح",
    verify1: "قم بتأكيد بريدك الإلكتروني",
    verify2: "لقد أرسلنا إليك بريدًا إلكترونيًا يحتوي على رابط التحقق.",
    verify3: "ألم تتلق الرابط؟",
    verify4: " إعادة إرسال",
    signInBtn: "تسجيل الدخول",
    myWorkSpace: "مساحة العمل",
    logOutBtn: "تسجيل الخروج",
    joinNow: "اشترك الان",
    contactUs: "اتصل بنا",
    about: "نبذة عنا",
    firstNamePlaceHolder: "الاسم الأول",
    lastNamePlaceHolder: "اسم العائلة",
    workEmailPlaceHolder: "بريد العمل الالكتروني",
    requiredEmailError: "البريد الالكتروني مطلوب",
    invalidEmailError: "يجب أن يكون البريد الإلكتروني صالحًا",
    submitBtn: "إرسال",
    nextBtn: "التالي",
    cancelBtn: "إلغاء",
    contBtn: "الاستمرار",
    skipBtn: "تجاوز",
    alreadyMember: "هل لديك حساب؟ تسجيل الدخول",
    signUpBtn: "إنشاء حساب",
    enterEmailPlaceHolder: "أدخل البريد الإلكتروني",
    backBtn: "عودة",
    siteDir: "rtl",
    siteAlign: "right",
    create0: "قم بإنشاء حساب شركتك",
    create1: "بيانات الاتصال",
    create2: "املأ بيانات الاتصال الخاصة بك، لا يمكن تغييرها لاحقاً",
    englishCharsAreAllowedError: "يُسمح بالأحرف الإنجليزية فقط",
    requiredFirstNameError: "الاسم الاول مطلوب",
    requiredLastNameError: "اسم العائلة مطلوب",
    enterPasswordPlaceHolder: "أدخل كلمة المرور",
    create3: "بيانات الشركة",
    companyPlaceHolder: " اسم الشركة",
    requiredCompanyError: "اسم الشركة مطلوب",
    create4:
      "يُسمح فقط باستخدام الأحرف والأرقام والشرطة والشرطة السفلية باللغة الانجليزية",
    companyIdPlaceHolder: "هوية الشركة",
    requiredCompanyIdError: "هوية الشركة lمطلوبة",
    notAvailableCompanyIdError: "هوية الشركة مستخدمة.",
    create5: "ادخل احرف وارقام  فقط",
    create6: "رابط حساب الشركة",
    create7: "اسم نطاق الشركة",
    create8: "من خلال إنشاء حساب ، فإنك توافق على",
    terms: "شروط ",
    and: "و",
    privacy: "سياسة الخصوصية",
    create9: "الخاصة بـ SafarHub",
    create10: "تم إنشاء حساب الشركة بنجاح",
    create11: "نحتاج إلى مزيد من المعلومات لتخصيص مساحة عملك",
    create12: "الذهاب إلى حساب الشركة دون ادخال المعلومات",
    onBoard1: "كم عدد الموظفين في الشركة؟",
    onBoard2: "اختر إجابة واحدة",
    onBoard3: "موظف",
    numberOfEmployeesArray1: "١ - ٩",
    numberOfEmployeesArray2: "١٠ - ٢٩",
    numberOfEmployeesArray3: "٩٩ - ٣٠",
    numberOfEmployeesArray4: "١٠٠ - ٤٩٩",
    numberOfEmployeesArray5: "أكثر من ٥٠٠",
    upload1: "قم بتحميل شعار شركتك",
    upload2: "يفضل ان تكون الصورة ٣٠٠ x ٣٠٠ بيكسل",
    upload3: "اسحب صورتك في هذه المنطقة أو",
    upload4: "انقر لاختيار صورة من المتصفحح",
    loginError1: "نطاق شركتك غير مسجل",
    loginError2: "اتصل بمدير السفر الخاص بك",
    loginError3: "إنشاء حساب شركة",
    menuItemApprovals: "طلبات السفر",
    menuItemMembers: "إدارة الأعضاء",
    menuItemTeams: "إدارة الفرق",
    menuItemPolicies: "سياسات السفر",
    menuItemReports: "التقارير",
    menuItemBookTrip: "احجز رحلة",
    menuItemMyTrips: "رحلاتي",
    menuItemFavorites: "المفضلة",
    menuItemMyApprovals: "طلباتي",
    menuItemSettings: "الإعدادات",
    menuItemCompTrips: "رحلات الشركة",
    menuItemBilling: "الفواتير",
    account: "الحساب",
    accountDesc: "إدارة معلومات الشركة",
    integrations: "إضافات",
    integrationsDesc: "ربط مساحة العمل الخاصة بك بتطبيقات أخرى مثل Expenses",
    rewardsProg: "برنامج المكافآت",
    rewardsProgDesc: "إنشاء وإدارة برنامج المكافآت الخاص بك",
    CompCards: "بطاقات الشركة",
    CompCardsDesc: "إدارة بطاقات الشركة للمسافرين",
    billingPalns: "الدفع والبرامج",
    billingPalnsDesc:
      "إعداد معلومات الدفع الخاصة بك وطريقة الدفع لمتابعة استخدام الخدمة",
    invoices: "الفواتير",
    invoicesDesc: "عرض الفواتير الخاصة باشتراكك",
    approvalsListDesc:
      "سيتم سرد طلبات الموافقة على السفر هنا. يمكنك عرض تفاصيل الرحلة أو الموافقة على / رفض طلبات السفر أو طلب مزيد من المعلومات. سيتم هنا إدراج الرحلات التي تتطلب موافقات فقط.",
    approvalListMsg1: "ليس لديك أي طلب موافقة قيد الانتظار",
    approvalListMsg2: "لم يتم الموافقة على أي طلب حتى الآن",
    approvalListMsg3: "لم يتم رفض أي طلب حتى الآن",
    approvalListMsg4: "ليس لديك أي طلب منتهي الصلاحية",
    pending: "قيد الانتظار",
    approved: "موافق عليه",
    declined: "مرفوض",
    expired: "منتهي",
    loading: "تحميل",
    nights: " ليال ",
    night: "ليلة واحدة",
    nightNoNumber: "ليلة واحدة",
    refundable: "مُستردة",
    nonrefundable: "غير مُستردة",
    slashNight: "/ليلة",
    outOfPolicy: "خارج السياسة",
    inPolicy: "ضمن السياسة",
    view: "عرض",
    approve: "موافقة",
    decline: "رفض",
    flight: "طيران",
    roundTrip: "ذهابا وعودة",
    oneWay: "ذهاب فقط",
    approveBook: "الموافقة على الحجز",
    approveBookMsg: "هل أنت متأكد أنك تريد الموافقة على هذه الرحلة؟",
    flightPriceChange: "تغير سعر الرحلة من ",
    roomPriceChange: "تغير سعر الغرفة من ",
    declineTrip: "رفض الرحلة",
    tripApproval: "الموافقة على الرحلة",
    declineBtn: "رفض",
    DeclineBookMsg: "هل أنت متأكد أنك تريد رفض الرحلة لـ",
    declineArray1: "خارج نطاق السياسة",
    declineArray2: "لا داعى للسفر",
    declineArray3: "تم إيجاد فندق آخر",
    declineArray4: "غير ذلك",
    declineArray5: "تم إيجاد رحلة أخرى",
    reason: "السبب",
    dashboardMsg1: "احجز رحلة عملك القادمة",
    dashboardMsg2: "أكمل البحث",
    dashboardMsg3: "رحلاتك القادمة",
    oneStop: "محطة توقف واحدة",
    twoStops: "توقفان",
    stops: "محطات توقف",
    inRewards: "في المكافآت",
    dashboardMsg4: "أكمل مساحة العمل الخاصة بك",
    dashboardMsg5: "قم ببناء فريقك",
    dashboardMsg6:
      "قم بدعوة زملاء العمل، وإنشاء وإدارة الفرق لتنظيم مساحة العمل الخاصة بك.",
    inviteNow: "أرسل دعوة",
    dashboardMsg7: "تعيين سياسات السفر",
    dashboardMsg8:
      "قم بإنشاء سياسات السفر الخاصة بشركتك، وتعيين حدود الحجز والموافقات.",
    createPolicy: "إنشاء السياسة",
    dashboardMsg9: "وفر أكثر بمكافأة موظفيك",
    dashboardMsg10: "حدد ميزانية السفر",
    dashboardMsg11: "قم بتعيين الحد الأعلى للسغر",
    dashboardMsg12: "امنح مكافآت",
    dashboardMsg13: "تحفيز موظفيك على حجز رحلات سفر بسعر أقل",
    dashboardMsg14: "وفر أكثر",
    dashboardMsg15:
      "يحجز الموظفون رحلات سفر بسعر أقل للحصول على نسبة مئوية من المدخرات",
    createProg: "أنشئ البرنامج الخاص بي",
    hotels: "فنادق",
    flights: "طيران",
    clearBtn: " حذف ",
    enterCityPlaceholder: "أدخل الوجهة أو اسم الفندق ...",
    enterCity: "أدخل الوجهة أو اسم الفندق ...",
    enterCityOnly: "أدخل اسم المدينة ...",
    searchHistory: "سجل البحث",
    chooseHotel: "الرجاء اختيار فندق أو وجهة",
    twoNights: " ليلتان ",
    twoNightsNoNumber: " ليلتان ",
    guests: " نزلاء ",
    adults: " بالغين ",
    adult: " بالغ ",
    children: " أطفال ",
    twoAdults: " بالغان ",
    twoGuests: " نزيلان ",
    guest: " نزيل ",
    twoChildren: " طفلان ",
    childAge: "عمر الطفل عند تسجيل الدخول",
    child: " طفل ",
    childAgeRequired: "يجب إدخال عمر الأطفال",
    searchBtn: "بحث",
    yourLocation: "موقعك",
    done: "تم",
    howLongStay: "كم ستبقى؟",
    checkIn: "الدخول",
    checkOut: "الخروج",
    whoIsTravel: "من يسافر؟",
    max6: "(٦ حد أقصى)",
    domesticHotels: "الفنادق المحلية والدولية",
    economy: "السياحية",
    premEconomy: "السياحية المتميزة",
    businessClass: "رجال الأعمال",
    firstClass: "الأولى",
    monthly: "شهريا",
    annually: "سنويا",
    maxNoOfTrips: "الحد الأقصى لعدد الرحلات",
    max9: "(٩ حد أقصى)",
    selectOriginPlaceholder: "اختر محطة المغادرة",
    selectOrigin: "اختر محطة المغادرة",
    pleaseSelectOrigin: "الرجاء اختيار محطة المغادرة",
    searchArrival: "البحث عن مطار الوصول",
    selectDesPlaceholder: "اختر محطة الوصول",
    selectDes: "اختر محطة الوصول",
    pleaseSelectDes: "الرجاء اختيار محطة الوصول",
    to: "إلى",
    from: "من",
    fromTeam: "من فريق",
    departing: "المغادرة",
    returning: "الوصول",
    traveler: "مسافر",
    twoTravelers: "مسافران",
    travelers: "مسافرين",
    searchDeparture: "ابحث عن مطار المغادرة",
    multiCity: "وجهات متعددة",
    flyFrom: "الطيران من",
    flyTo: "الطيران إلى",
    nextDay: " (اليوم التالي)",
    prevDay: " (اليوم السابق)",
    nonStop: "بدون توقف",
    childAgeOnly: "عمر الطفل",
    members: "الأعضاء",
    membersDesc:
      "قم بدعوة موظفك إلى مساحة العمل للتوفير في سفر العمل. يمكنك تعيين سياسات السفر والموافقة عليها وتنظيم الأعضاء في فرق.",
    inviteUsers: "دعوة مستخدمين",
    uploadViaCSV: "إضافة باستخدام CSV",
    all: "الكل",
    inActive: "غير فعال",
    deactivated: "غير فعال",
    name: "الاسم",
    role: "الوظيفة",
    team: "الفريق",
    policy: "السياسة",
    deActivate: "إلغاء تفعيل",
    reActivate: "اعادة تفعيل",
    editProfile: "تعديل الملف الشخصي",
    member: "عضو",
    admin: "مشرف",
    teamManager: "مدير الفريق",
    owner: "مالك الحساب",
    resetInvite: "إعادة إرسال الدعوة",
    withdrawInvite: "سحب الدعوة",
    withdrawInviteTitle: "سحب دعوة",
    active: "فعال",
    searchForUsers: "ابحث عن مستخدمين",
    useCSV1: " استخدم ملف csv لإرسال الدعوات. يجب ادخال البريد الإلكتروني.",
    useCSV2: " يمكن العثور على ملف عينة",
    here: " هنا",
    chooseFile: "اختر ملف",
    oneInvite: "دعوة",
    manyInvites: "دعوات",
    sendInvites: "أرسل الدعوات",
    csvRequired: "يجب اختيار ملف CSV",
    processInvites: "إرسال ",
    outOf: " من ",
    invitations: " دعوات",
    clickForDetails: "انقر للاطلاع على التفاصيل",
    startOver: "ابدأ من جديد",
    successfulOne: "{$INTERPOLATION} دعوة تمت بنجاح ",
    successfulMany: "{$INTERPOLATION} دعوات تمت بنجاح ",
    failedOne: "{$INTERPOLATION} دعوة باءت بالفشل ",
    failedMany: "{$INTERPOLATION} دعوات باءت بالفشل ",
    popUpTitle: "رفع ملف CSV",
    emailFormat: "يرجى التأكد من استخدام بريد إلكتروني صحيح",
    inviteOnWay: "تم إرسال الدعوة",
    emailAddresses: "عناوين البريد الإلكتروني",
    insertEmail:
      "أدخل عناوين البريد (اضغط على Enter لإدراج عناوين بريد متعددة)",
    emailsRequired: "عناوين البريد الإلكتروني مطلوبة",
    createNewTeam: "أنشئ فريقًا جديدًا",
    setAsAdmin: "تعيين كمشرف",
    setAsAdminDesc:
      "يمكن للمشرف إدارة الأعضاء والفرق وسياسات السفر وعرض سجل الاشتراك والفواتير وإضافات مساحة العمل.",
    sendInvite: "أرسل دعوة",
    deactivateMsg1: "هل أنت متأكد أنك تريد إلغاء تفعيل حساب ",
    deactivateMsg2:
      "إلغاء تفعيل حساب العضو ، لن يتمكن من استخدام حساب شركتك للحجز.",
    deactivateMsg3: "ساعدنا في تحسين خدماتنا",
    reasonRequired: "السبب مطلوب",
    userDeactivate1: "لم يعد المستخدم جزءًا من الشركة بعد الآن",
    userDeactivate2: "الخدمة باهظة الثمن ونحن نحاول خفض التكاليف",
    userDeactivate3: "لن يسافر المستخدم بعد الآن",
    userDeactivate4: "لن يسافر المستخدم في اي وقت قريب",
    userDeactivate5: "الاتجاه إلى منتج آخر",
    deactivatePlaceholder:
      "قدم لنا المزيد من التفاصيل للمساعدة في تحسين خدماتنا.",
    deactivateUser: "إلغاء تفعيل حساب مستخدم",
    on: " في ",
    reactivate2: " وستكون فاتورتك القادمة ",
    reactivate1: "بإعادة تفعيل هذا الحساب ، سيتوجب عليك دفع مبلغ ",
    reactivateUser: "رسوم إعادة تفعيل المستخدم",
    confirmBtn: "تأكييد",
    withdrawInviteMsg: "تم سحب دعوتك بنجاح",
    withdrawInvite1: " هل أنت متأكد أنك تريد سحب دعوة ",
    withdrawInvite2: " ؟",
    unassigned: "غير محدد",
    travelPolicy: "سياسة السفر",
    noPayment: "ليس لديه طرق للدفع",
    activate: "تفعيل",
    joinDate: "تاريخ الانضمام",
    edit: "تعديل",
    createNewPolicy: "أنشئ سياسة جديدة",
    createNewPayment: "أشئ طريقة دفع جديدة",
    save: "حفظ",
    setAsAdminUser:
      " دور المسؤول يسمح لـ {$INTERPOLATION} بإدارة الأعضاء والفرق وسياسات السفر وعرض سجل الاشتراك والفواتير وإضافات مساحة العمل. ",
    onlyAssignedCards: "يمكن للعضو الحجز باستخدام البطاقات المخصصة فقط",
    paymentMethods: "طرق الدفع",
    teamName: "اسم الفريق",
    noAvailablePay: "لا توجد طرق دفع متاحة",
    teamNameRequired: "اسم الفريق مطلوب",
    oneTeamOnly: "يمكن للعضو أن يكون جزءًا من فريق واحد فقط",
    noMembers: "بدون أعضاء",
    manager: "المدير",
    setToTeamManager: "سيتم إرسال جميع موافقات رحلات الفريق إلى مدير الفريق",
    removeManagerMsg:
      "بإزالة المدير ، سيتم إرسال جميع طلبات الموافقة على الحجز إلى المالك.",
    selectPolicy: "حدد سياسة سفر الفريق",
    onlyBookAssignedCard:
      "يمكن لأعضاء الفريق الحجز باستخدام البطاقات المخصصة فقط",
    createNewTeamTitle: "إنشاء فريق جديد",
    createTeam: "أنشئ الفريق",
    cardPlaceholder: "اسم صاحب البطاقة",
    cardPlaceholderRequired: "اسم صاحب البطاقة مطلوب",
    cardPlaceholderInvalid: "اسم صاحب البطاقة غير صالح",
    enterCardDetails: "أدخل تفاصيل البطاقة هنا",
    cardAlias: "اسم البطاقة (اختياري)",
    WeAccept: "نقبل بـ",
    countOnUs: "يمكنك الاعتماد علينا",
    secureData: "يتم نقل البيانات بشكل آمن",
    protectInfo: "نقوم بحماية المعلومات الشخصية الخاصة بك",
    infoExchanged: "يتم تشفير المعلومات المتبادلة قبل إرسالها.",
    addNewCard: "إضافة بطاقة جديدة",
    flightPolicy: "سياسة الطيران",
    skipFlightRules: "تخطي قواعد الطيران",
    fareClass: "فئة الرحلة",
    flightPriceCap: "الحد الأعلى لسعر الرحلة",
    domeAndInterPrice: "تحديد الحد الأعلى لأسعار الرحلات المحلية والدولية",
    interFlightPriceCap: "الحد الأعلى لسعر رحلة الطيران الدولية",
    interFlightCapRequired: " الحد الأعلى لسعر الرحلة الدولية مطلوب",
    domFlightPriceCap: " الحد الأعلى لسعر الرحلة المحلية",
    domFlightCapRequired: "سعر الرحلة المحلية مطلوب",
    hotelPolicy: "سياسة الفندق",
    skipHotelRules: "تخطي قواعد الفندق",
    avgNightlyPriceRequired: "الحد الأعلى لسعر الليلة في الفندق مطلوب",
    cityExcep: "يمكنك إضافة استثناء لكل مدينة",
    addCity: "أضف مدينة",
    policyName: "اسم السياسة",
    policyNameRequired: "اسم السياسة مطلوب",
    avgNightlyPrice: "الحد الأعلى لسعر الليلة في الفندق",
    nightlyPriceLimit: "حد سعر الليلة",
    nightlyPriceLimitCapital: "حد سعر الليلة",
    flightPriceRequired: "الحد الأعلى لرحلة الطيران مطلوب",
    interPriceCap: "الحد الأعلى رحلة الطيران الدولية",
    interFlightPriceLimit: "الحد الأعلى لرحلة الطيران الدولية مطلوب",
    domFlightPriceLimit: "الحد الأعلى لرحلة الطيران المحلية مطلوب",
    domPriceCap: "الحد الأعلى  لسعر رحلة الطيران المحلية",
    enterCityPolicy: "أدخل المدينة ...",
    approval: "طلب الموافقة",
    requireApproval: "اختر الوقت الذي يطلب فيه المسافرون الموافقة",
    noApproval: "لا يلزم طلب موافقة",
    recommended: "مُوصى به",
    allTrips: "جميع الرحلات",
    pendingApproval: "قيد الإنتظار",
    moreGuests: "نزلاء إضافيين",
    twoMoreGuests: "نزيلان إضافيان",
    oneMoreGuest: "نزيل إضافي",
    cancellationPolicies: "سياسات الإلغاء",
    tripSummary: "ملخص الرحلة",
    approvalAvgNightlyPrice: "متوسط ​​سعر الليلة",
    totalPrice: "السعر الكلي",
    nightlyPriceCap: "الحد الأعلى لليلة",
    paymentMethod: "طريقة الدفع",
    cardEndsWith: "البطاقة التي تنتهي بـ",
    willCharged: "سيتم خصم منها إجمالي",
    charged: "تم دفعها من خلال بطاقة تنتهي بـ",
    payPalWillCharge: "سيتم الدفع من خلال PayPal",
    payPalCharged: "تم الدفع من خلال PayPal",
    me: "أنا",
    clarify: "طلب توضيح",
    checkInNoDash: "الدخول",
    checkOutNoDash: "المغادرة",
    youSelected: "لقد اخترت:",
    stay: " ",
    priceSummary: "ملخص السعر",
    taxAndFees: "الضرائب والرسوم",
    bookedFor: "تم الحجز لـ",
    LeaveComments: "اترك تعليقك هنا",
    requestMoreInfo: "طلب المزيد من المعلومات",
    enterMsgError: "الرجاء ترك رسالة",
    priceCap: "الحد الأعلى للسعر",
    cabinClass: "فئة الطيران",
    refundTicket: "التذكرة قابلة للاسترداد",
    nonRefundTicket: "التذكرة غير قابلة للاسترداد",
    flightCancellation:
      "نحن لا نفرض أي رسوم تغيير أو إلغاء. عندما تفرض شركة الطيران هذه الرسوم بناءً على قواعدها وسياساتها فسيتم حساب التكلفة عليك.",
    multipleAirlines: "خطوط طيران متعددة:",
    showTripDetails: "عرض تفاصيل الرحلة",
    flightDetails: "تفاصيل الرحلة",
    fareRules: "قوانين الأجرة",
    carryOnBag: "تشمل حقيبة يد محمولة",
    layoverIn: "توقف في",
    roundTripPerTraveler: "ذهاباً وعودة لكل مسافر",
    perTraveler: "لكل مسافر",
    selectFlight: "اختيار الرحلة",
    operatedBy: "تديرها",
    duration: "المدة:",
    earn: "اكسب",
    deleteBtn: "حذف",
    teams: "الفرق",
    teamDesc:
      "قم بإنشاء وإدارة الفرق وإضافة الأعضاء إلى فريق وتعيين سياسات السفر.",
    addTeam: "إضافة فريق",
    editTeam: "تعديل الفريق",
    addMembers: "إضافة أعضاء",
    addMembersWithThe: "إضافة الأعضاء",
    addMemberdMsg: "تمت إضافة جميع مستخدمي الشركة بالفعل إلى هذا الفريق",
    newTeam: "فريق جديد",
    noManager: "لا يوجد مدير",
    removeBtn: "إزالة",
    removeTeam: "إزالة فريق",
    removeMember: "إزالة عضو",
    removePolicy: "إزالة سياسة",
    removePolicyWithThe: "إزالة السياسة",
    removeAdmin: "إزالة مسؤول",
    removeTeamWithThe: "إزالة الفريق",
    removeMemberWithThe: "إزالة العضو",
    removeMemberMsg1: "هل أنت متأكد من أنك تريد إزالة",
    removeMemberMsg2:
      "؟ ",
    cityAvgNightPrice: "الحد الأعلى لسعر الليلة في المدينة",
    newPolicy: "سياسة جديدة",
    hotel: "فندق",
    appliedTo: "تُطبق على",
    removePolicyMsg1: "هل أنت متأكد أنك تريد حذف سياسة",
    removePolicyMsg2:
      "؟ ستخضع جميع الفرق والأعضاء (إن وجدت) في هذه السياسة لسياسة (All Employees) ",
    accountOverview: "نظرة عامة على الحساب",
    activeMembers: "الأعضاء الفعَّالين",
    pendingInvitations: "دعوات قيد الانتظار",
    inactiveMembers: "الأعضاء غير الفعَّالين",
    approvalRequests: "طلبات الموافقة",
    savings: "المدخرات",
    totalBelowPolicyLimit: "إجمالي $ أقل من حد السياسة",
    totalExceedsPolicyLimit: "إجمالي $ يتجاوز حد السياسة",
    totalSavings: "إجمالي المدخرات",
    policyOverview: "نظرة عامة على السياسة",
    bookingWithinPolicyLimit: "الحجز ضمن حدود السياسة",
    bookingViolatePolicyLimit: "الحجز خارج حدود السياسة",
    topDestinations: "أفضل الوجهات",
    noTopDestinations: "لا توجد وجهات مفضلة",
    topTravelers: "أفضل المسافرين",
    noTopTravelers: "لا يوجد مسافرين مفضلين",
    bookingsFlights: "الحجوزات (الرحلات)",
    numberOfFlights: "عدد الرحلات",
    confirmedFlights: "الرحلات المؤكدة",
    canceledFlights: "الرحلات الملغاة",
    completedFlights: "الرحلات المكتملة",
    upcomingFlights: "الرحلات القادمة",
    internationalFlights: "الرحلات الدولية",
    domesticFlights: "الرحلات المحلية",
    avgPricePerFlight: "متوسط ​​سعر الرحلة",
    totalRefundAmount: "إجمالي المبلغ المسترد",
    bookingsHotels: "الحجوزات (فنادق)",
    numberOfBookings: "عدد الحجوزات",
    confirmedBookings: "الحجوزات المؤكدة",
    canceledBookings: "الحجوزات الملغاة",
    averageNightStay: "متوسط ​​الليلة / الإقامة",
    totalSpendAmount: "إجمالي مبلغ الإنفاق",
    refundAmountCanceled: "المبلغ المسترد (تم إلغاؤه)",
    approvalAvgNightlyPriceSmall: "متوسط ​​سعر الليلة",
    upcoming: "القادمة",
    completed: "المكتملة",
    canceled: "الملغية",
    upcomingStays: "الحجوزات القادمة",
    completedStays: "الحجوزات المنجزة",
    bookAgain: "احجز مرة أخرى",
    showMore: "عرض المزيد",
    myTripsMsg: "ستظهر رحلاتك هنا بمجرد حجز رحلة.",
    myFavoriteHotels: "الفنادق المفضلة",
    myFavoriteMsg:
      "عندما تجد فندقًا يعجبك، احفظه، شاركه مع أصدقائك وارجع لتجده هنا مرة أخرى.",
    findHotel: "ابحث عن فندق",
    stars: "نجوم",
    oneStar: "نجمة",
    withdrawn: "مسحوب",
    withdraw: "سحب",
    noPending: "ليس لديك أي طلب قيد الانتظار",
    noApproved: "ليس لديك أي طلب موافق عليه",
    noDeclined: "ليس لديك أي طلب مرفوض",
    noExpired: "ليس لديك أي طلب منتهي الصلاحية",
    noWithdrawn: "ليس لديك أي طلب تم سحبه",
    withdrawMsg:
      "هل أنت متأكد أنك تريد سحب طلبك؟ لا يمكن التراجع عن هذا الإجراء!",
    withdrawApprovalRequest: "سحب طلب موافقة",
    commentRequired: "التعليق مطلوب",
    send: "إرسال",
    typeMsg: "اكتب رسالتك هنا",
    shareVoucher: "إرسال بريد إلكتروني إلى عنوان آخر",
    shareViaEmailHotelVoucher:
      "أدخل عناوين البريد الإلكتروني أو حدد من القائمة",
    activeBookingCanceled: "تم إلغاء حجزك",
    activeBookingCompleted: "اكتمل الحجز الخاص بك",
    activeBookingConfirmed: "تم تأكيد حجزك",
    inActiveBookingCanceled: "تم إلغاء الحجز",
    inActiveBookingCompleted: "اكتمل الحجز",
    inActiveBookingConfirmed: "تم تأكيد الحجز",
    specialRequests: "طلبات خاصة",
    bookingSummary: "ملخص الحجز",
    confirmationNumber: "رقم التأكيد",
    emailAddress: "عنوان البريد الإلكتروني",
    phoneNumber: "رقم الهاتف",
    roomDetails: "تفاصيل الغرفة",
    stayDetails: "تغاصيل الإقامة",
    paymentInfo: "معلومات الدفع",
    location: "الموقع",
    roomName: "اسم الغرفة",
    freeWiFi: "واى فاى مجانى",
    freeparking: "موقف سيارات مجاني",
    totalStay: "إجمالي الإقامة",
    guestDetails: "تفاصيل النزيل",
    amenities: "المرافق",
    additionalInfo: "معلومات إضافية",
    cancelBooking: "إلغاء الحجز",
    print: "طباعة",
    email: "بريد",
    endsWith: "تنتهي بـ",
    payPalAccount: "حساب PayPal",
    refundInfo: "معلومات الاسترداد",
    cancelPenalty: "غرامة الإلغاء",
    yourReview: "تقييمك",
    viewMap: "عرض الخريطة",
    uploadExpense: "تحميل المصاريف",
    voucherSent: "تم إرسال الإيصال إلى",
    guestName: "اسم النزيل",
    numberOfAdults: "عدد البالغين",
    paymentDetails: "تفاصيل الدفع",
    state: "الولاية",
    numberOfChildren: "عدد الاطفال",
    price: "السعر",
    discount: "الخصم",
    totalPaid: "مجموع المبالغ المدفوعة",
    totalRefund: "إجمالي المبلغ المسترد",
    cancelOption2: "لقد وجدت فندقًا أفضل",
    cancelAt: "إلغاء الحجز الخاص بك في",
    cancelReason: "الرجاء تحديد سبب الإلغاء",
    selectReason: "اختر سبباًً",
    cancelOption1: "تغيرت خططي",
    sureToCancel: "هل أنت متأكد أنك تريد إلغاء حجزك؟",
    cancelOption3: "أنا فقط أريد الإلغاء",
    yesCancel: "نعم ، إلغاء الحجز الخاص بي",
    noAndBack: "لا ، أعدني",
    coupon: "قسيمة",
    roomInformation: "معلومات الغرفة",
    hotelInformation: "معلومات الفندق",
    address: "العنوان",
    city: "المدينة",
    country: "الدولة",
    stayInformation: "معلومات الإقامة",
    checkInDate: "تاريخ الدخول",
    checkOutDate: "تاريخ المغادرة",
    totalNights: "مجموع الليالي",
    hotelName: "اسم الفندق",
    beforeYouTravel6: "تعيين المقعد",
    noteBus: "ملاحظة: هذه خدمة حافلات",
    noteTrain: "ملاحظة: هذه خدمة قطار",
    travelDates: "مواعيد السفر",
    safarHubConfirmationNumber: "رقم تأكيد SafarHub",
    theTraverlers: "المسافرون",
    departure: "المغادرة",
    beforeYouTravel1: "قبل السفر",
    beforeYouTravel2: "تأكد من حمل تذكرتك الإلكترونية معك في المطار.",
    beforeYouTravel3:
      "أحضر معك بطاقة هوية تحمل صورتك صادرة عن جهة حكومية عند تسجيل الوصول والأمن بالمطار.",
    beforeYouTravel4:
      "عند السفر الدولي ، تأكد من إحضار جميع وثائق السفر الضرورية (جواز السفر ، التأشيرة ، تصريح العبور أو أي مستندات أخرى مطلوبة عند وجهة الوصول).",
    bookingDetails4: "يمكنك الاتصال بشركة الطيران بشأن",
    beforeYouTravel5: "وجبات خاصة",
    beforeYouTravel7: "أي مساعدة خاصة مطلوبة",
    cancellation1: "الإلغاء",
    needOurHelp: "بحاجة الى مساعدتنا؟",
    contactSupport:
      "يمكنك التواصل معنا عبر البريد الإلكتروني على support@safarhub.com",
    callNumber: "اتصل بنا على",
    airline: "شركة طيران",
    return: "الرجوع",
    letsTryAgain: "هناك خطأ ما. لنحاول ذلك مرة أخرى.",
    airlinesConfirmationNumbers: "أرقام تأكيد شركات الطيران",
    lastUploadDate: "تاريخ آخر تحميل: ",
    weConfirm: " نحن نؤكد حجزك في {$INTERPOLATION}",
    printCancelHotel: " نحن نؤكد أن حجزك في  {$INTERPOLATION} تم إلغاؤه.",
    printConfirmHotel1: " من {$INTERPOLATION}",
    printConfirmHotel2: " حتى {$INTERPOLATION}.",
    teamCreated: "تم إنشاء فريق جديد بنجاح",
    removeTeam1: "هل أنت متأكد أنك تريد حذف فريق ",
    removeTeam2: "؟",
    removeTeam3: "بحذف فريق ",
    removeTeam4: "سيكون جميع أعضاء الفريق تحت فريق All Employees",
    contactFor: "تواصل مع بشركة الطيران لـ",
    departOn: "المغادرة في",
    additionalInformation: "معلومات إضافية",
    additionalFees: "رسوم اضافية",
    contactAirline:
      "يرجى الاتصال بشركة الطيران للحصول على أي أمتعة إضافية أو خدمات إضافية.",
    photoId:
      "مطلوب هوية حكومية تحمل صورة شخصية لتسجيل الوصول والأمن في المطار.",
    internationalTravel:
      "السفر الدولي: مطلوب إثبات الجنسية مع جميع الوثائق المطلوبة. على سبيل المثال جواز السفر ، التأشيرة ، تصريح العبور ... إلخ.",
    allNeededDocs: "تأكد من التحقق من جميع المستندات اللازمة قبل السفر.",
    at: "الساعة",
    specialHelp: "مساعدة خاصة",
    printCancelFlight: "تم إلغاء رحلتك ، كل تفاصيل إلغاء رحلتك مذكورة أدناه.",
    ticket: "تذكرة",
    printConfirmFlight1: " نحن نؤكد رحلتك إلى {$INTERPOLATION} في يوم ",
    printConfirmFlight2: "رقم تأكيد SafarHub هو  {$INTERPOLATION}",
    confirmNewPassword: "تأكيد كلمة المرور الجديدة",
    rewards: "المكافآت",
    contactNumber: "رقم الاتصال",
    gender: "الجنس",
    dob: "تاريخ الميلاد",
    travelDoc: "وثيقة سفر",
    add: "إضافة",
    langPlaceHolder: "اللغة",
    changeLang: "تغيير اللغة",
    changePassword: "تغيير كلمة المرور",
    currentPassword: "كلمة المرور الحالية",
    newPassword: "كلمة المرور الجديدة",
    profile: "الملف الشخصي",
    saveNewPassword: "حفظ كلمة المرور الجديدة",
    passwordShort: "كلمة المرور قصيرة جدا",
    newPasswordRequired: "مطلوب كلمة مرور جديدة",
    confirmPasswordRequired: "مطلوب تأكيد كلمة المرور",
    noTravelDocs: "ليس لديك أي وثيقة سفر",
    expiredOn: "انتهي في",
    expiresOn: "ينتهي في",
    noProgram: "ليس لديك أي برنامج",
    profileMsg:
      "لا يمكن تغيير عنوان البريد الإلكتروني والاسم للعضو. إذا كنت ترغب في تعديل البريد الإلكتروني أو اسمك ، يرجى الاتصال بمسؤول مساحة العمل الخاصة بك.",
    companySetting: "إعدادت الشركة",
    flyerProgram: "برامج المسافر الدائم",
    flyerProgramOne: "برنامج المسافر الدائم",
    removeFlyerProg: "إزالة وثيقة سفر",
    removeTravelDoc: "إزالة برنامج المسافر",
    countryCode: "رمز البلد",
    selectCountryCode: "الرجاء تحديد رمز البلد الخاص بك",
    enterPhoneNumber: "يرجى إدخال رقم الهاتف الخاص بك",
    male: "ذكر",
    female: "أنثى",
    memberInfoUpdated: "تم تحديث معلومات العضو بنجاح",
    memberInfo: "معلومات العضو",
    AirlineProgName: "شركة الطيران واسم البرنام",
    programNumber: "رقم البرنامج",
    AirlineProgNameRequired: "اسم شركة الطيران والبرنامج مطلوب",
    programNumberRequired: "رقم البرنامج مطلوب",
    addFlyerProg: "إضافة برنامج مسافر",
    flyerProgUpdated: "تم تحديث البرنامج بنجاح",
    flyerProgAdded: "تمت إضافة البرنامج بنجاح",
    removeImageMsg:
      "هل أنت متأكد أنك تريد إزالة صورة ملفك الشخصي؟ لا يمكن التراجع عن هذا الإجراء!",
    changeImage: "تغيير الصورة",
    removeImage: "إزالة الصورة",
    removeProfileImage: "إزالة صورة الملف الشخصي",
    removeProgMsg:
      "هل أنت متأكد أنك تريد حذف هذا البرنامج؟ لا يمكن التراجع عن هذا الإجراء!",
    removeDocMsg:
      "هل أنت متأكد أنك تريد حذف وثيقة السفر هذه؟ لا يمكن التراجع عن هذا الإجراء!",
    travelDocDeleted: "تم حذف وثيقة السفر بنجاح",
    flyerProgDeleted: "تم حذف برنامج المسافر بنجاح",
    travelDocAdded: "تمت إضافة وثيقة السفر بنجاح",
    passportCountry: "بلد إصدار جواز السفر",
    passportNumber: "رقم جواز السفر",
    passportExpiryDate: "تاريخ انتهاء صلاحية جواز السفر",
    passportCountryRequired: "بلد إصدار جواز السفر مطلوب",
    passportNumberRequired: "رقم جواز السفر مطلوب",
    expiryDateRequired: "تاريخ انتهاء الصلاحية مطلوب",
    addTravelDoc: "إضافة وثيقة السفر",
    travelDocUpdated: "تم تحديث وثيقة السفر بنجاح",
    redeemGift: "استبدل بطاقات الهدايا الخاصة بك بأكثر من 50 علامة تجارية",
    rewardsNoThe: " مكافآت {$INTERPOLATION}",
    enjoyBenefits: "استمتع بفوائد كونك عضوًا",
    book: "احجز",
    bookTravel: "احجز رحلتك تحت سياسة السفر الخاصة بك",
    earnDollarRewards: "اكسب مكافآت بالدولار٪ من المدخرات عند إتمام الرحلة",
    redeem: "استرداد",
    available: "متوفر",
    rewardsBalance: "رصيد المكافآت",
    redeemMyGift: "استرداد بطاقة الهدية",
    searchForBrand: "ابحث عن علامة تجارية",
    recentActivity: "النشاط الأخير",
    viewAll: "عرض الكل",
    successfulRedeem:
      "$ تم استرداد {$INTERPOLATION}لقد أرسلنا لك بطاقة الهدايا عبر البريد الإلكتروني. بطاقة هديةز",
    passwordChars: "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل",
    noMatchPassword: "كلمة المرور الجديدة وكلمة المرور التأكيدية غير متطابقتين",
    history: "السجل",
    date: "التاريخ",
    activity: "الحركة",
    amount: "المبلغ",
    userPayMsg1:
      "من السهل حفظ طريقة الدفع المفضلة لديك في حساب SafarHub الخاص بك.",
    addCard: "إضافة بطاقة",
    noAlias: "لا يوجد اسم",
    primary: "أساسية",
    userPayMsg2: "ما عليك سوى تحديد 'تذكر طريقة الدفع' أثناء الحجز.",
    setPrimary: "تعيين كأساسي",
    deleteUserCard: "احذر ، أنت تحذف طريقة الدفع!",
    editCard: "تعديل البطاقة",
    alias: "اسم البطاقة",
    deleteCard: "حذف البطاقة",
    deletePay:
      "هل أنت متأكد أنك تريد حذف طريقة الدفع؟ لا يمكن التراجع عن هذا الإجراء!",
    yourCardSaved: "تم حفظ بطاقتك.",
    cards: "البطاقات",
    newCard: "بطاقة جديدة",
    compCardMsg1:
      " يمكن لموظفي {$INTERPOLATION} الذين قاموا بالتسجيل في SafarHub دفع الرسوم بطرق الدفع الموضحة أدناه ",
    teamsPayments: "مدفوعات الفرق",
    compCardMsg2:
      "يمكنك تعيين طرق دفع لفرق مختلفة ، إذا لم يتم تعيين أي فريق لطريقة الدفع ، فيمكن عندئذٍ استخدامها من قبل جميع الموظفين",
    editCardAlias: "تعديل اسم البطاقة",
    billingHistory: "سجل الفواتير",
    number: "الرقم",
    download: "تحميل",
    noInvoice: "ليس لديك أي فاتورة في حسابك.",
    contactSupportInvoice: "إذا كان لديك أي أسئلة ، فاتصل بدعم العملاء على",
    deleteRewardProgMsg: "لماذا تقوم بحذف برنامج المكافآت الخاص بك؟",
    notWantShareSaving: "لا أريد مشاركة التوفير مع موظفيي",
    notEffective: "لم يكن فعال في الادخار",
    redemptionLimited: "شبكة الاسترداد محدودة",
    rewardProgramRemoved: "تمت إزالة برنامج المكافآت بنجاح",
    employeeRewards: "مكافآت الموظفين",
    fromTotalBelowCap: "من المبلغ الإجمالي الأقل من الحد الأقصى للسياسة",
    createProgram: "إنشاء برنامج",
    howRewardsWorks: "كيف يعمل برنامج المكافآت",
    howRewardsWorks1:
      "1- قم بإنشاء سياسة السفر الخاصة بك وحدد الحد الأعلى للفندق / الرحلة",
    howRewardsWorks2:
      "2- لأي حجز أقل من الحد الأقصى للوثيقة ، يحصل الموظف على نسبة من المدخرات على شكل مكافآت بالدولار",
    howRewardsWorks3:
      "3- يتم اعتماد المكافآت في محفظة الموظف بمجرد اكتمال الرحلة",
    howRewardsWorks4: "4- يمكن للموظفين استبدال مكافآتهم بقسائم تجارية مختلفة.",
    hints: "تلميحات",
    hints1: "1- القيمة الموصى بها لنسبة حجز الفندق 30٪",
    hints2: "2- القيمة الموصى بها لنسبة حجز الرحلة 20٪",
    hints3: "3- سيرى الموظف قيمة المكافآت قبل الحجز",
    rewardsProgram: " برنامج مكافآت {$INTERPOLATION}",
    deleteRewardsProg: "حذف برنامج المكافآت",
    rewardProgramUpdated: "تم تحديث برنامج المكافآت بنجاح",
    rewardProgramCreated: "تم إنشاء برنامج المكافآت بنجاح",
    plsCorrectFlightReward:
      "الرجاء تحديد النسبة المئوية الصحيحة لمكافأة الرحلة",
    plsCorrectHotelReward: "الرجاء تحديد النسبة المئوية الصحيحة لمكافأة الفندق",
    rewardsProgMsg1: "ليس لديك برنامج مكافآت فعال",
    rewardsProgMsg2:
      "يحفز برنامج المكافآت موظفيك على حجز أسعار أقل من الحد الأقصى لسياسة السفر مما يؤدي إلى توفير المزيد من نفقات السفر.",
    hotelRewards: "مكافآت الموظفين على التوفير في حجز الفندق",
    flightRewards: "مكافآت الموظفين على التوفير في حجز الرحلة",
    disconnect: "فصل",
    connect: "ربط",
    integrationsMsg:
      "سيتمكن الأعضاء من تحميل جميع النفقات بنقرة واحدة إلى حساب Expensify الخاص بهم ..",
    partnerUserSecret: "Partner User Secret",
    partnerUserIDrequired: "Partner User ID مطلوب",
    partnerUserSecretRequired: "Partner User Secret مطلوب",
    partnerId: "Partner User ID",
    expensifyInteg: "إضافة Expensify",
    integrationsMsg1: "1- اضغط على",
    integrationsMsg2: "2- تسجيل الدخول بحساب Expensify الخاص بك",
    integrationsMsg3:
      "3- زوج من بيانات الاعتماد: سيتم إنشاء partnerUserID و partnerUserSecret على الصفحة.",
    integrationsMsg4:
      "4- إذا كان لديك بالفعل بيانات الاعتماد التي تم إنشاؤها ، فاتصل بمسؤول حساب Expensify للحصول على partnerUserSecret أو انقر فوق إعادة إنشاء partnerUserSecret الخاص بك",
    removeAccount: "إزالة الحساب",
    connectedApps: "التطبيقات المتصلة",
    appName: "التطبيق",
    status: "الحالة",
    connected: "متصل",
    disconnected: "غير متصل",
    creatNewReport: "(إنشاء تقرير جديد)",
    expensifyPolicy: "سياسة Expensify",
    upload: "تحميل",
    expensifyReportName: "اسم تقرير Expensify",
    legalNameAddress: "الاسم والعنوان القانوني",
    displayName: "اسم العرض",
    annualTravelSpend: "نفقات السفر السنوية",
    legalCompanyName: "اسم الشركة القانوني",
    industry: "المجال",
    totalNumberEmployees: "العدد الإجمالي للموظفين",
    legalNameAddressNote: "سيتم تضمين هذه التفاصيل في جميع فواتيرك المستقبلية.",
    transferAccountOwnership: "نقل ملكية الحساب",
    transferAccountOwnershipMsg1:
      "يمكن أن يكون لحساب شركتك مالك واحد فقط في كل مرة ، ويمكن للمالك فقط الوصول الكامل إلى حساب الشركة.",
    transferAccountOwnershipMsg2: "لنقل الملكية إلى عضو آخر ، انقر",
    orContactUsAt: "أو اتصل بنا على",
    notSet: "غير محدد",
    address1: "العنوان 1",
    address2: "العنوان 2",
    ZIPCode: "الرمز البريدي",
    invalidNumber:
      "عفواًً! يرجى استخدام الأرقام والنقاط والشرطات والأقواس فقط عند إدخال رقم هاتفك.",
    displayNameRequired: "اسم العرض مطلوب",
    displayNameNote:
      "سيرى الموظفون اسم العرض لشركتك في صفحة رسائل البريد الإلكتروني الخاصة بالعمل والحجوزات والدعوة.",
    contactOwner:
      "يمكن لمالك الحساب فقط تعديل خطة الاشتراك وطرق الدفع. الرجاء التواصل مع",
    memberNameRequired: "اسم العضو مطلوب",
    transferWorkOwner: "نقل ملكية مساحة العمل",
    transferWorkOwner2: "اختر العضو الذي تريد نقل الملكية إليه",
    transferWorkOwner1:
      " يمكنك نقل الملكية إلى أي عضو مسجل بـ {$INTERPOLATION}. لا يمكن التراجع عن هذا الإجراء! ",
    confirmTransfer: "أدخل كلمة المرور الخاصة بك لتأكيد نقل الملكية إلى",
    wrongPassword: "كلمة مرور خاطئة",
    memberName: "اسم العضو",
    transferOwnership: "نقل الملكية",
    nextInvoice: "الفاتورة التالية",
    viewBillingHistory: "عرض سجل الفواتير",
    billSubMsg1:
      "SafarHub يفرض 30 دولارًا شهريًا لأول 5 أعضاء فعالين. أي مستخدم يتجاوز الخمسة الأوائل ، ستدفع 10 دولارات لكل مستخدم شهريًا.",
    billingCycle: "دورة الفواتير: ",
    nextBillingDate: "تاريخ الفاتورة التالية",
    mySubscription: "اشتراكي",
    limitedAccount:
      "لديك صلاحيات محدودة على حسابك. اشترك مرة أخرى وتمتع بصلاحية الحجز والميزات الأخرى. ",
    lastSub: "الاشتراك الأخير",
    subExpiredOn: "انتهى اشتراكك في",
    SubCancel: "إلغاء الاشتراك",
    changeCard: "تغيير البطاقة",
    contactOwnerTile: "تواصل مع المالك",
    cancelYourSub: "إلغاء اشتراكك",
    annual: "سنوي",
    billingPlans: "خطط الدفع",
    switchToAnnualBilling: "التبديل إلى الدفع السنوي",
    editBillingCard: "تعديل بطاقة الدفع",
    deleteSubscription: "حذف الاشتراك",
    subReactivated: "انتبه ، تغير السعر",
    warningPriceChanged: "تحذير ، تغير السعر",
    uploadExpenses: "تحميل المصاريف",
    switchToPlanBilling: " التبديل إلى {$INTERPOLATION} دفع ",
    switchToPlanBilling1: " التبديل إلى {$name} دفع ",
    activateMySubscription: "تفعيل اشتراكي",
    save10annual: "وفر 10٪ مع الدفع السنوي",
    switchAndSave: "قم بالتبديل إلى الاشتراك السنوي ووفر 180 دولارًا",
    nextSubscription: "الاشتراك التالي",
    switchToAnnual: "التبديل إلى الدفع السنوي",
    lastInvoice: "آخر فاتورة",
    subWillEndOn: "سينتهي اشتراكك في",
    subWillDeactivate:
      "سيتم إلغاء تفعيل حسابك ولن يتمكن الأعضاء من تسجيل الدخول.",
    keepMySub: "احتفظ باشتراكي",
    lastBillingDate: "تاريخ آخر فاتورة",
    noPayCardInAccount: "لم تتم إضافة أي بطاقة دفع إلى حسابك",
    addPaymentMethod: "إضافة طريقة دفع",
    cardUsedForSub: "سيتم استخدام هذه البطاقة لفواتير اشتراكك",
    bySwitching: " بالتبديل إلى طريقة دفع {$INTERPOLATION} سيضاف إلى فاتورتك ",
    declineArraySub3: "الانتقال إلى برنامج آخر",
    declineArraySub1: "لم نعد نسافر",
    declineArraySub2: "الخدمة باهظة الثمن",
    reasons: "الأسباب",
    cancelSubscription: "إلغاء الاشتراك",
    whyDeactivateSub: "لماذا تقوم بإلغاء اشتراك حسابك؟",
    cancelCompanySub: " إلغاء اشتراك {$INTERPOLATION} ",
    cancelSubMsg1: "إلغاء اشتراكك يعني أنه لن يتم تجديد حسابك في",
    cancelSubMsg2:
      "بمجرد الإلغاء ، سيفقد فريقك الصلاحيات ولن يتمكن من حجز الرحلات.",
    no: "لا",
    yesCancelSub: "نعم، إلغاء",
    sureToCancelSub: "هل أنت متأكد أنك تريد الإلغاء؟",
    contactIfNeedHelp: "إذا كنت بحاجة إلى مساعدة ، يرجى التواصل معنا",
    reviews: "تقييمات",
    sortBy: "ترتيب حسب",
    highToLow: "من الأعلى للأقل",
    lowToHigh: "من الأقل للأعلى",
    starRating: "التصنيف بالنجوم",
    filter: "تصنيف",
    searchByName: "البحث عن طريق الاسم",
    priceRangeNightly: "نطاق السعر (كل ليلة)",
    popularFilters: "أشهر التصنيفات",
    freeCancellation: "إلغاء مجاني",
    clearAllFilters: "مسح كل عوامل التصنيف",
    showInPolicyHotelsOnly: "عرض الفنادق ضمن السياسة فقط",
    guestsRating: "تقييم النزلاء",
    guestRatingSort: "تقييم النزلاء",
    ourFavorites: "المفضلة لدينا",
    noAvailableDates: "غير متوفر لمواعيد سفرك",
    tryNewDates: "جرب مواعيد جديدة!",
    taxAndFeesIncluded: "شامل الضرائب والرسوم",
    chooseRoom: "اختر الغرفة",
    viewHotel: "عرض الفندق",
    earnRewards: "اكسب مكافآت",
    getDollarRewards:
      " احصل على مكافآت بالدولار عندما تحجز فندقًا أقل من سياسة السفر الخاصة بشركتك. مقابل كل دولار توفره ، تحصل على {$INTERPOLATION} كمكافأة. ",
    earnInRewards: " اكسب {$INTERPOLATION}$ في المكافآت ",
    earnInRewardsHotelDetails: " اكسب {$INTERPOLATION}$ في المكافآت ",
    earnInRewardsFlightList: " اكسب {$INTERPOLATION}$ في المكافآت ",
    weAreSorrySearch:
      "معذرةً ، ليس لدينا أي فندق يتوافق مع عوامل التصنيف الخاصة بك",
    PleaseClearFliter: "يرجى ضبط عوامل التصنيف الخاصة بك أو مسحها",
    noSearchCriteria:
      "لا توجد نتائج تطابق معايير التصنيف الخاصة بك. يرجى تعديل عامل التصنيف الخاص بك وحاول مرة أخرى",
    withinPolicy: "ضمن السياسة",
    reset: "إعادة ضبط",
    "5star": "5-نجوم",
    "4star": "4-نجوم",
    "3star": "3-نجوم",
    "2star": "2-نجوم",
    "1star": "1-نجوم",
    freeBreakfast: "إفطار مجاني",
    isAvailableBookNow: "متاح - احجز الآن!",
    filters: "تصنيفات",
    searchThisArea: "ابحث في هذه المنطقة",
    guestRating: "تصنيف النزيل",
    freeAirportTransport: "نقل مجاني للمطار",
    bar: "بار",
    businessCenter: "مركز أعمال",
    gym: "نادي رياضي",
    swimmingPool: "حمام سباحة",
    map: "خريطة",
    sort: "ترتيب",
    specialDeal: "صفقة خاصة",
    viewList: "عرض القائمة",
    closeMap: "إغلاق الخريطة",
    poor: "سيء",
    fair: "مقبول",
    good: "جيد",
    veryGood: "جيد جداً",
    excellent: "ممتاز",
    noMatchFound: "لا يوجد تطابق",
    healthSafetyMeasuresMsg:
      "اتخذت هذه المنشأة تدابير إضافية للصحة والنظافة وتلتزم بـ",
    share: "مشاركة",
    viewRooms: "مشاهدة الغرف",
    poweredByGoogle: "مدعوم من جوجل",
    basedOnReviews: " استناداً إلى {$INTERPOLATION} تقييمات ",
    seeWhatNear: "شاهد ما هو بالقرب منك",
    readMore: "اقرأ المزيد",
    hotelOverview: "نظرة عامة على الفندق",
    readLess: "اقرأ أقل",
    healthSafetyMeasures: "إجراءات الصحة والسلامة",
    viewAllPhotos: "عرض كل الصور",
    rooms: "الغرف",
    features: "المميزات",
    showRoomInfo: "إظهار معلومات الغرفة",
    bookNow: "احجز الآن",
    showMoreRoomRates: "عرض المزيد من أسعار الغرف",
    showLessRoomRates: "عرض أقل من أسعار الغرف",
    showMoreRooms: "عرض المزيد من الغرف",
    showLessRooms: "عرض أقل من الغرف",
    nearBy: "الأماكن المجاورة",
    refresh: "تحديث",
    refreshHotel: "قم بتحديث صفحة الفندق هذه للحصول على أحدث الأسعار.",
    noAvailability:
      "للأسف ، لا توجد غرف متاحة في التواريخ المحددة. يمكن أن تتغير الأسعار بشكل متكرر. الرجاء محاولة تغيير التواريخ المحددة أو عدد النزلاء والمحاولة مرة أخرى",
    weHateThis: "نحن نكره حدوث هذا!",
    priceFlightChangeMsg:
      "لقد تغير سعر هذه الرحلة. يمكن أن تتغير الأسعار بشكل متكرر ، يرجى التحقق من السعر المحدث.",
    priceChangeMsg:
      "لقد تغير سعر هذه الغرفة. يمكن أن تتغير الأسعار بشكل متكرر ، يرجى التحقق أدناه لمعرفة السعر المحدث والحجز الآن.",
    shareViaEmail: "سوف نشارك تفاصيل الفندق عبر البريد الإلكتروني",
    shareHotel: "مشاركة الفندق",
    secureBooking: "حجز آمن",
    almostDone: "(لقد شارفت على الانتهاء)",
    notAndSign: "لست {$INTERPOLATION}؟ تسجيل الخروج",
    actFast: "تصرف بسرعة! الأسعار والغرف المتاحة قد تتغير!",
    invalidFirstName: "الاسم الأول غير صحيح",
    howContactYou: "كيف يمكننا الاتصال بك؟",
    travellerRequired: " المسافر مطلوب",
    whoTravelling: "من المسافر؟",
    invalidLastName: "اسم العائلة غير صحيح",
    invalidEmail: "بريد إلكتروني خاطئ",
    phoneNumberRequired: "رقم الهاتف مطلوب",
    invalidPhoneNumber: "رقم الهاتف غير صحيح",
    bookingYourRoom: "حجز غرفتك ..",
    anySpecialRequests: "أي طلبات خاصة؟",
    specialRequestsGuaranteed:
      "الطلبات الخاصة غير مضمونة وهي تعتمد على تقدير الفندق ",
    messageTooLong: "رسالتك طويلة جدا",
    choosePaymentMethod: "الرجاء اختيار طريقة الدفع",
    creditDebitCard: "بطاقة السحب الآلي / الائتمان",
    makeSurePaymDetails:
      "تأكد من ملء جميع التفاصيل ثم اضغط على زر الخروج في الأسفل ، ستتم إعادة توجيهك لإكمال الدفع. بعد الانتهاء ، ستعود إلى SafarHub لعرض تأكيد الحجز الخاص بك.",
    rememberPaymentMethod: "تذكر طريقة الدفع",
    yourCardwillBeSaved: "سيتم حفظ بطاقتك واستخدامها بمجرد الموافقة على الحجز",
    reservationTerms: "شروط الحجز",
    ofSafarHub: "الخاصة بـ SafarHub.",
    youWillCharged: " سيتم تحصيل مبلغ ",
    fromYou: "منك",
    termsAndConditions: "الأحكام والشروط",
    reviewSummary: "ملخص التقييمات",
    completeBookAgree: "بإكمال هذا الحجز ، فإنك توافق على",
    payPalNotAvailable:
      "PayPal غير متوفر في الوقت الحالي ، حاول مرة أخرى لاحقًا",
    checkOutPayPal:
      "تحقق من PayPal لمتابعة طلب الموافقة الخاص بك. سنفوض فقط بالدفع. سيتم تحصيل المبلغ المدفوع عند الموافقة على حجزك.",
    approvalMsg3: "الموافقة على الحجز مطلوبة. سيتم إرسال طلب الموافقة إلى",
    approvalMsg2: ". سيتم حجز غرفتك تلقائيًا بمجرد الموافقة عليها.",
    approvalMsg1:
      " يتجاوز هذا الحجز سياسة السفر الخاصة بك . أنت بحاجة إلى موافقة لحجز الغرفة. سيتم إرسال طلب الموافقة إلى ",
    requestapproval: "طلب موافقة",
    Total: "الإجمالي",
    fillRequiredInfo: "يرجى ملء جميع المعلومات المطلوبة",
    confirmBooking: "تأكيد الحجز",
    totalPayNow: "إجمالي للدفع الآن",
    yourPriceSummary: "ملخص السعر الخاص بك",
    ratesNote: "* الأسعار والضرائب والرسوم محددة بالدولار الأمريكي.",
    whyBookWithUs: "لماذا تحجز معنا؟",
    priceGuarantee: "ضمان السعر",
    "247support": "24/7 دعم العملاء",
    newPaymentMethod: " إضافة طريقة دفع جديدة",
    uponCompleting: "سوف تكسب {$INTERPOLATION}$ عند إكمال هذه الإقامة",
    manageFlightUponCompleting:
      "سوف تكسب {$INTERPOLATION}$ عند إكمال هذه الرحلة",
    voucherUponCompleting: "سوف تكسب {$INTERPOLATION}$ عند إكمال هذه الإقامة",
    manageHotelUponCompleting:
      "سوف تكسب {$INTERPOLATION}$ عند إكمال هذه الإقامة",
    successfullyBookedHotel: "لقد حجزت فندقك بنجاح!",
    shareByEmail: "مشاركة بالبريد الإلكتروني",
    updatedReportName: "اسم التقرير المحدث",
    shortest: "الأقصر",
    roundTripNoDash: "ذهابا وعودة",
    theStops: "محطات التوقف",
    starting: "تبدأ من",
    airlines: "الخطوط الجوية",
    airports: "المطارات",
    showInPolicyFlightsOnly: "عرض الرحلات ضمن السياسة فقط",
    showLess: "عرض أقل",
    cheapest: "الأقل سعراً",
    oneWayNoDash: "ذهاب فقط",
    best: "الأفضل",
    in: "في",
    noSearchCriteria1:
      "لا توجد نتائج تطابق معايير البحث الخاصة بك. يرجى تعديل بحثك وحاول مرة أخرى",
    select: " اختر ",
    weAreSorrySearchFlight:
      "معذرةً ، ليس لدينا أي رحلة تطابق عوامل التصفية الخاصة بك",
    moreThanTwoStops: "2+ محطات توقف",
    oneSeatLeft: "مقعد متبقي",
    seatsLeft: "مقاعد متبقية",
    startOverBtn: "البدء من جديد",
    flightsPricesNotification:
      "تتغير أسعار الرحلات بشكل متكرر بناءً على توفر شركة الطيران. للحصول على أفضل الأسعار ، قم بتحديث بحثك.",
    searchResultExpired: "انتهت صلاحية نتيجة البحث",
    refreshFlights: "قم بتحديث  بحثك عن الرحلات  للحصول على أحدث الأسعار",
    uponCompletingFlightBooking:
      "سوف تكسب {$INTERPOLATION}$ عند إكمال هذه الرحلة",
    uponCompletingFlightVoucher:
      "سوف تكسب {$INTERPOLATION}$ عند إكمال هذه الرحلة",
    OnewayPerTraveler: "ذهاب فقط لكل راكب",
    approvalMsgFlight2: ". سيتم حجز رحلتك تلقائيًا بمجرد الموافقة عليها.",
    "approvalMsgFlight1": "يتجاوز هذا الحجز سياسة السفر الخاصة بك. سيتم إرسال طلب الموافقة إلى",
    jan: "01 - يناير",
    feb: "02 - فبراير",
    mar: "03 - مارس",
    apr: "04 - أبريل",
    may: "05 - مايو",
    jun: "06 - يونيو",
    jul: "07 - يوليو",
    aug: "08 - أغسطس",
    sep: "09 - سبتمبر",
    oct: "10 - أكتوبر",
    nov: "11 - نوفمبر",
    dec: "12 - ديمسبر",
    ticketIs: "التذكرة",
    passportExpiryDateRequired: "تاريخ انتهاء صلاحية جواز السفر مطلوب",
    dobRequired: "تاريخ الميلاد مطلوب",
    year: "السنة",
    day: "اليوم",
    month: "الشهر",
    genderRequired: "اختيار نوع الجنس مطلوب",
    flightBookingSpecial:
      "حسب ما هو متوفر. يرجى الاتصال بشركة الطيران لتأكيد طلبات المساعدة الخاصة.",
    mealRequest: "طلب وجبة",
    traveler1Adult: "المسافر 1: بالغ",
    flightBookingMsgPassport:
      "يجب أن تتطابق أسماء المسافرين تماماً مع بطاقة الهوية التي تحمل الصورة الصادرة عن الجهة الحكومية.",
    travelerXAdult: "المسافر {$INTERPOLATION}: بالغ",
    youWillChargedFLightBooking:
      " سيتم تحصيل {$INTERPOLATION}$ دولارًا أمريكيًا منك. ",
    termsOfBooking: "شروط الحجز:",
    checkTravelerName:
      "تحقق من كتابة اسم المسافر. قواعد شركات الطيران تتطلب تطابق الأسماء تماماً مع بطاقة الهوية  التي تحمل الصورة الصادرة عن الجهة الحكومية.",
    reviewTicket: "يرجى مراجعة تفاصيل تذكرة الرحلة قبل إتمام الدفع.",
    reviewTermsPolicy: "مراجعة الشروط والسياسة",
    primaryContact: "(المرجع الأساسي)",
    travelerDetails: "تفاصيل المسافر",
    baggageInfo: "معلومات الأمتعة",
    bookingYourFlight: "حجز رحلتك ..",
    completeBooking: "أكمل حجزك",
    selectOther: "اختر",
    needHelpCall: "تحتاج مساعدة؟ اتصل بنا على",
    anotherRoom: "غرفة أخرى",
    anotherFlight: "رحلة أخرى",
    applyPolicyToMember:
      "عند تطبيق سياسة على أحد الأعضاء ، فإنها ستحل محل سياسة السفر للفريق الخاص بالعضو",
    successfullyBookedFlight: "تم حجز رحلتك بنجاح!",
    voucherMsg1: "تم حجز رحلتك بنجاح وجاري إصدار التذاكر من قبل شركة الطيران.",
    voucherMsg2:
      "لا داعي للتواصل معنا في الوقت الحالي ، ستتلقى بريدًا إلكترونيًا بجميع التفاصيل في غضون ساعات قليلة مع رقم التذكرة.",
    subscriptionSummary: "ملخص الاشتراك",
    activateSubscription: "تفعيل الاشتراك",
    earnInRewardsDeactivated: "${$INTERPOLATION} في المكافآت ",
    manageYourBusinessInSlack: "قم بإدارة سفر عملك في Slack",
    connectSafarHubApp: "ربط تطبيق SafarHub",
    enterEmailInSlack:
      "أدخل عنوان البريد الإلكتروني الخاص بالعمل لتسجيل الدخول إلى مساحة العمل الخاصة بك.",
    installedSafarHubToSlack:
      "لقد نجحت في تثبيت تطبيق SafarHub على مساحة عمل Slack الخاصة بك.",
    loggedInAs: " تم تسجيل دخول",
    connectSafarHubAppToSlack:
      "قم بربط تطبيق SafarHub بمساحة عمل Slack الخاصة بك",
    openSlack: "افتح Slack",
    checkYourSlack: "تحقق من Slack واتبع التعليمات لربط تطبيق SafarHub",
    plsInstallSlackAppFirst: "من فضلك ، قم بتثبيت تطبيق Slack أولاً",
    safarHubApp: "تطبيق SafarHub",
    connectSafarHubToSlack: "قم بربط SafarHub بمساحة عمل Slack الخاصة بك",
    connectedSafarHubBot:
      "لقد نجحت في ربط برنامج SafarHub bot بمساحة عمل Slack الخاصة بك.",
    firstInstallSafarHub:
      "أولاً ، قم بتثبيت تطبيق SafarHub على مساحة عمل Slack الخاصة بك",
    somethingWentWrongSafarHubApp: "حدث خطأ ما. فشل تثبيت تطبيق SafarHub",
    contactOurSupportAt: "تواصل مع دعمنا",
    tryAgain: "حاول مجدداً",
    termsOfUse: "الشروط و الأحكام",
    aboutUs: "من نحن",
    copyRight: "جميع الحقوق محفوظة ",
    subjectRequired: "الموضوع مطلوب",
    messageRequired: "الرسالة مطلوبة",
    contactMsgPlaceHolder: "زودنا ببعض التفاصيل وأخبرنا كيف يمكننا مساعدتك ...",
    nameIsRequired: "الاسم مطلوب",
    yourEmail: "بريدك الالكتروني",
    contactMsg1: "مساعدة SafarHub ودعم العملاء",
    contactMsg2:
      "نحن هنا لمساعدتك في الحصول على مكافآت SafarHub الخاصة بك على مدار الساعة طوال أيام الأسبوع. للبدء ، يرجى إرسال بريد إلكتروني إلينا وتزويدنا ببعض التفاصيل.",
    subject: "الموضوع",
    yourName: "اسمك",
    reviewsRecent: "التقييمات. التقييمات الأخيرة:",
    aboutPage1: "ادفع أقل لأي فندق. في أي مكان وفي أي وقت",
    aboutPage2:
      "تأسست SafarHub في عام 2017 بهدف واحد: تقديم تجربة حجز فندق بسيطة وخالية من الإجهاد للمسافرين مع ضمان أفضل الأسعار والتكنولوجيا لتنبيهك بأفضل صفقة ممكنة.",
    aboutPage3: "توفير الوقت والمال",
    aboutPage4:
      "مع تقنية SafarHub الخاصة بالذكاء الاصطناعي، سيتلقى المسافرون تنبيهات مخصصة حتى يتمكنوا من تأمين أفضل سعر ممكن للفندق  يؤمن SafarHub أن اكتشاف أفضل فندق وحجزه بأفضل سعر يجب أن يكون سريعًا وخاليًا من المتاعب.",
    aboutPage5: "أفضل سعر مضمون",
    aboutPage6:
      "هل تحب أن تشعر بالرضا عند ادخار النقود؟ لن يوفر لك SafarHub الوقت والمال فحسب ، بل سيوفر لك أفضل سعر مضمون. بصفتك مشتركًا في SafarHub ، ستكون أول من يكتشف الصفقات غير المنشورة والمخفية قبل أي شخص آخر.",
    aboutPage7:
      "Sوعد SafarHub هو تقديم أفضل العروض الفندقية التي يمكن تخيلها. ستشعر بالثقة في معرفة أنه مع SafarHub ، ستتلقى عروض مخصصة بدون وسيلة للتحايل ومخصصة لاحتياجاتك.",
    AF: "أفغانستان",
    AX: "جزر أولاند",
    AL: "ألبانيا",
    DZ: "الجزائر",
    AS: "ساموا الأمريكية",
    AD: "أندورا",
    AO: "أنغولا",
    AI: "أنغويلا",
    AQ: "القارة القطبية الجنوبية",
    AG: "أنتيغوا وباربودا",
    AR: "الأرجنتين",
    AM: "أرمينيا",
    AW: "أروبا",
    AU: "أستراليا",
    AT: "النمسا",
    AZ: "أذربيجان",
    BS: "الباهاماس",
    BH: "البحرين",
    BD: "بنغلاديش",
    BB: "باربادوس",
    BY: "بيلاروس",
    BE: "بلجيكا",
    BZ: "بليز",
    BJ: "بنين",
    BM: "برمودا",
    BT: "بوتان",
    BO: "بوليفيا",
    BA: "البوسنة/الهرسك",
    BW: "بوتسوانا",
    BR: "البرازيل",
    IO: "إقليم المحيط الهندي البريطاني",
    BN: "بروناي",
    BG: "بلغاريا",
    BF: "بوركينا فاسو",
    BI: "بوروندي",
    KH: "كمبوديا",
    CM: "الكاميرون",
    CA: "كندا",
    CV: "الرأس الأخضر",
    KY: "جزر كايمان",
    CF: "جمهورية أفريقيا الوسطى",
    TD: "تشاد",
    CL: "تشيلي",
    CN: "الصين",
    CX: "جزيرة كريسماس",
    CC: "جزر كوكوس",
    CO: "كولومبيا",
    KM: "جزر القمر",
    CG: "جمهورية الكونغو",
    CD: "جمهورية الكونغو الديمقراطية",
    CK: "جزر كوك",
    CR: "كوستاريكا",
    CI: "ساحل العاج",
    HR: "كرواتيا",
    CU: "كرواتيا",
    CY: "قبرص",
    CZ: "جمهورية التشيك",
    DK: "الدنمارك",
    DJ: "جيبوتي",
    DM: "دومينيكا",
    DO: "جمهورية الدومينيكان",
    EC: "الإكوادور",
    EG: "مصر",
    SV: "السلفادور",
    GQ: "غينيا الاستوائية",
    ER: "إريتريا",
    EE: "إستونيا",
    ET: "إثيوبيا",
    FK: "جزر فوكلاند",
    FO: "جزر فارو",
    FJ: "فيجي",
    FI: "فنلندا",
    FR: "فرنسا",
    GF: "غويانا الفرنسية",
    PF: "بولينزيا الفرنسية",
    GA: "الغابون",
    GM: "غامبيا",
    GT: "غواتيمالا",
    GE: "جورجيا",
    DE: "ألمانيا",
    GH: "غانا",
    GI: "جبل طارق",
    GR: "اليونان",
    GL: "جرينلاند",
    GD: "غرينادا",
    GP: "غوادلوب",
    GU: "غوام",
    GG: "يرنزي",
    GN: "غينيا",
    GW: "غينيا بيساو",
    GY: "غيانا",
    HT: "هايتي",
    VA: "الفاتيكان",
    HN: "هندوراس",
    HK: "هونغ كونغ",
    HU: "المجر",
    IS: "آيسلندا",
    IN: "الهند",
    ID: "إندونيسيا",
    IR: "إيران",
    IQ: "العراق",
    IE: "أيرلندا",
    IM: "جزيرة مان",
    IL: "سرائيل",
    IT: "إيطاليا",
    JM: "جامايكا",
    JP: "اليابان",
    JE: "جيرزي",
    JO: "الأردن",
    KZ: "كازاخستان",
    KE: "كينيا",
    KI: "كيريباتي",
    KP: "كوريا الشمالية",
    KR: "كوريا الجنوبية",
    KW: "الكويت",
    KG: "قيرغيزستان",
    LA: "لاوس",
    LV: "لاتفيا",
    LB: "لبنان",
    LS: "ليسوتو",
    LR: "ليبيريا",
    LY: "ليبيا",
    LI: "ليختنشتاين",
    LT: "ليتوانيا",
    LU: "لوكسمبورغ",
    MO: "ماكاو",
    MK: "مقدونيا",
    MG: "مدغشقر",
    MW: "مالاوي",
    MY: "ماليزيا",
    MV: "جزر المالديف",
    ML: "مالي",
    MT: "مالطا",
    MH: "جزر مارشال",
    MQ: "مارتينيك",
    MR: "موريتانيا",
    MU: "موريشيوس",
    YT: "مايوت",
    MX: "المكسيك",
    FM: "ولايات ميكرونيسيا المتحدة",
    MD: "مولدوفا",
    MC: "موناكو",
    MN: "منغوليا",
    ME: "الجبل الأسود",
    MS: "مونتسرات",
    MA: "المغرب",
    MZ: "موزمبيق",
    MM: "ميانمار",
    NA: "ناميبيا",
    NR: "ناورو",
    NP: "نيبال",
    NL: "هولندا",
    AN: "جزر الأنتيل الهولندية",
    NC: "كاليدونيا الجديدة",
    NZ: "نيوزيلندا",
    NI: "نيكاراغوا",
    NE: "النيجر",
    NG: "نيجيريا",
    NU: "نييوي",
    NF: "جزيرة نورفولك",
    MP: "جزر ماريانا الشمالية",
    NO: "النرويج",
    OM: "عمان",
    PK: "باكستان",
    PW: "بالاو",
    PS: "فلسطين",
    PA: "بنما",
    PG: "بابوا غينيا الجديدة",
    PY: "باراغواي",
    PE: "بيرو",
    PH: "الفلبين",
    PN: "جزر بيتكيرن",
    PL: "بولندا",
    PT: "البرتغال",
    PR: "بورتوريكو",
    QA: "قطر",
    RO: "رومانيا",
    RU: "روسيا",
    RW: "رواندا",
    RE: "لا ريونيون",
    BL: "سان بارتيلمي",
    SH: "سانت هيلانة وأسينشين وتريستان دا كونا",
    KN: "سانت كيتس ونيفيس",
    LC: "سانت لوسيا",
    MF: "تجمع سان مارتين",
    PM: "سان بيير وميكلون",
    VC: "سانت فينسنت والغرينادين",
    WS: "ساموا",
    SM: "سان مارينو",
    ST: "ساو تومي وبرينسيب",
    SA: "السعودية",
    SN: "السنغال",
    RS: "صربيا",
    SC: "سيشل",
    SL: "سيراليون",
    SG: "سنغافورة",
    SK: "سلوفاكيا",
    SI: "سلوفينيا",
    SB: "جزر سليمان",
    SO: "الصومال",
    ZA: "جنوب أفريقيا",
    GS: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    ES: "إسبانيا",
    LK: "سريلانكا",
    SD: "السودان",
    SR: "سورينام",
    SJ: "سفالبارد ويان ماين",
    SZ: "إسواتيني",
    SE: "السويد",
    CH: "سويسرا",
    SY: "سوريا",
    TW: "تايوان",
    TJ: "طاجيكستان",
    TZ: "تنزانيا",
    TH: "تايلاند",
    TL: "تيمور الشرقية",
    TG: "توغو",
    TK: "توكيلاو",
    TO: "تونغا",
    TT: "ترينيداد وتوباغو",
    TN: "تونس",
    TR: "تركيا",
    TM: "تركمانستان",
    TC: "جزر توركس وكايكوس",
    TV: "توفالو",
    UG: "أوغندا",
    UA: "أوكرانيا",
    AE: "الإمارات العربية المتحدة",
    GB: "المملكة المتحدة",
    US: "الولايات المتحدة",
    UY: "أورغواي",
    UZ: "أوزباكستان",
    VU: "فانواتو",
    VE: "فنزويلا",
    VN: "فيتنام",
    VG: "الجزر العذراء البريطانية",
    VI: "الجزر العذراء الأمريكية",
    WF: "واليس وفوتونا",
    YE: "اليمن",
    ZM: "زامبيا",
    ZW: "زيمبابوي",
    AL_state: "Alabama",
    AK_state: "Alaska",
    AS_state: "American Samoa",
    AZ_state: "Arizona",
    AR_state: "Arkansas",
    CA_state: "California",
    CO_state: "Colorado",
    CT_state: "Connecticut",
    DE_state: "Delaware",
    DC_state: "District Of Columbia",
    FM_state: "Federated States Of Micronesia",
    FL_state: "Florida",
    GA_state: "Georgia",
    GU_state: "Guam",
    HI_state: "Hawaii",
    ID_state: "Idaho",
    IL_state: "Illinois",
    IN_state: "Indiana",
    IA_state: "Iowa",
    KS_state: "Kansas",
    KY_state: "Kentucky",
    LA_state: "Louisiana",
    ME_state: "Maine",
    MH_state: "Marshall Islands",
    MD_state: "Maryland",
    MA_state: "Massachusetts",
    MI_state: "Michigan",
    MN_state: "Minnesota",
    MS_state: "Mississippi",
    MO_state: "Missouri",
    MT_state: "Montana",
    NE_state: "Nebraska",
    NV_state: "Nevada",
    NH_state: "New Hampshire",
    NJ_state: "New Jersey",
    NM_state: "New Mexico",
    NY_state: "New York",
    NC_state: "North Carolina",
    ND_state: "North Dakota",
    MP_state: "Northern Mariana Islands",
    OH_state: "Ohio",
    OK_state: "Oklahoma",
    OR_state: "Oregon",
    PW_state: "Palau",
    PA_state: "Pennsylvania",
    PR_state: "Puerto Rico",
    RI_state: "Rhode Island",
    SC_state: "South Carolina",
    SD_state: "South Dakota",
    TN_state: "Tennessee",
    TX_state: "Texas",
    UT_state: "Utah",
    VT_state: "Vermont",
    VI_state: "Virgin Islands",
    VA_state: "Virginia",
    WA_state: "Washington",
    WV_state: "West Virginia",
    WI_state: "Wisconsin",
    WY_state: "Wyoming",
    industry1: "Accounting",
    industry2: "Airlines/Aviation",
    industry3: "Alternative Dispute Resolution",
    industry4: "Alternative Medicine",
    industry5: "Animation",
    industry6: "Apparel & Fashion",
    industry7: "Architecture & Planning",
    industry8: "Arts and Crafts",
    industry9: "Automotive",
    industry10: "Aviation Aerospace",
    industry11: "Banking",
    industry12: "Biotechnology",
    industry13: "Broadcast Media",
    industry14: "Building Materials",
    industry15: "Business Supplies and Equipment",
    industry16: "Capital Markets",
    industry17: "Chemicals",
    industry18: "Civic & Social Organization",
    industry19: "Civil Engineering",
    industry20: "Commercial Real Estate",
    industry21: "Computer & Network Security",
    industry22: "Computer Games",
    industry23: "Computer Hardware",
    industry24: "Computer Networking",
    industry25: "Computer Software",
    industry26: "Internet",
    industry27: "Construction",
    industry28: "Consumer Electronics",
    industry29: "Consumer Goods",
    industry30: "Consumer Services",
    industry31: "Cosmetics",
    industry32: "Dairy",
    industry33: "Defense & Space",
    industry34: "Design",
    industry35: "Education Management",
    industry36: "E-Learning",
    industry37: "Electrical/Electronic Manufacturing",
    industry38: "Entertainment",
    industry39: "Environmental Services",
    industry40: "Events Services",
    industry41: "Executive Office",
    industry42: "Facilities Services",
    industry43: "Farming",
    industry44: "Financial Services",
    industry45: "Fine Art",
    industry46: "Fishery",
    industry47: "Food & Beverages",
    industry48: "Food Production",
    industry49: "Fund-Raising",
    industry50: "Furniture",
    industry51: "Gambling & Casinos",
    industry52: "Glass, Ceramics & Concrete",
    industry53: "Government Administration",
    industry54: "Government Relations",
    industry55: "Graphic Design",
    industry56: "Health, Wellness and Fitness",
    industry57: "Higher Education",
    industry58: "Hospital & Health Care",
    industry59: "Hospitality",
    industry60: "Human Resources",
    industry61: "Import and Export",
    industry62: "Individual & Family Services",
    industry63: "Industrial Automation",
    industry64: "Information Services",
    industry65: "Information Technology and Services",
    industry66: "Insurance",
    industry67: "International Affairs",
    industry68: "International Trade and Development",
    industry69: "Investment Banking",
    industry70: "Investment Management",
    industry71: "Judiciary",
    industry72: "Law Enforcement",
    industry73: "Law Practice",
    industry74: "Legal Services",
    industry75: "Legislative Office",
    industry76: "Leisure, Travel & Tourism",
    industry77: "Libraries",
    industry78: "Logistics and Supply Chain",
    industry79: "Luxury Goods & Jewelry",
    industry80: "Machinery",
    industry81: "Management Consulting",
    industry82: "Maritime",
    industry83: "Market Research",
    industry84: "Marketing and Advertising",
    industry85: "Mechanical or Industrial Engineering",
    industry86: "Media Production",
    industry87: "Medical Devices",
    industry88: "Medical Practice",
    industry89: "Mental Health Care",
    industry90: "Military",
    industry91: "Mining & Metals",
    industry92: "Motion Pictures and Film",
    industry93: "Museums and Institutions",
    industry94: "Music",
    industry95: "Nanotechnology",
    industry96: "Newspapers",
    industry97: "Nonprofit Organization Management",
    industry98: "Oil & Energy",
    industry99: "Online Media",
    industry100: "Outsourcing/Offshoring",
    industry101: "Package/Freight Delivery",
    industry102: "Packaging and Containers",
    industry103: "Paper & Forest Products",
    industry104: "Performing Arts",
    industry105: "Pharmaceuticals",
    industry106: "Philanthropy",
    industry107: "Photography",
    industry108: "Plastics",
    industry109: "Political Organization",
    industry110: "Primary/Secondary Education",
    industry111: "Printing",
    industry112: "Professional Training & Coaching",
    industry113: "Program Development",
    industry114: "Public Policy",
    industry115: "Public Relations and Communications",
    industry116: "Public Safety",
    industry117: "Publishing",
    industry118: "Railroad Manufacture",
    industry119: "Ranching",
    industry120: "Real Estate",
    industry121: "Recreational Facilities and Services",
    industry122: "Religious Institutions",
    industry123: "Renewables & Environment",
    industry124: "Research",
    industry125: "Restaurants",
    industry126: "Retail",
    industry127: "Security and Investigations",
    industry128: "Semiconductors",
    industry129: "Shipbuilding",
    industry130: "Sporting Goods",
    industry131: "Sports",
    industry132: "Staffing and Recruiting",
    industry133: "Supermarkets",
    industry134: "Telecommunications",
    industry135: "Textiles",
    industry136: "Think Tanks",
    industry137: "Tobacco",
    industry138: "Translation and Localization",
    industry139: "Transportation/Trucking/Railroad",
    industry140: "Utilities",
    industry141: "Venture Capital & Private Equity",
    industry142: "Veterinary",
    industry143: "Warehousing",
    industry144: "Wholesale",
    industry145: "Wine and Spirits",
    industry146: "Wireless",
    industry147: "Writing and Editing",
    footerTitle1: "للشركات",
    footerTitle2: "معلومات أكثر",
    footerTitle3: "قانوني",
    footerTitle4: "الدعم",
    footerTitleMain:
      "منصة سحابية بتقنية حديثة تعمل على تبسيط وأتمتة رحلات العمل في دقائق.",
    footerFAQ: "الأسئلة الشائعة",
    whySafarHub: "لماذا SafarHub",
    termsAndConditionsCapital: "الأحكام والشروط",
    noTripApprovalRequests: "ليس لديك أي طلبات موافقة",
    ApprovalRequiredOn: "الموافقة مطلوبة لـ: ",
    nightlyPriceLimitDetails: "حد سعر الليلة: ",
    supportCSV: "يتم دعم ملفات CSV فقط",
    dragDropFile: "اسحب ملفك هنا أو ",
    clickHere: "انقر ",
    chooseHere: "لاختيار ملف من المتصفح",
    membersToActivate: "الأعضاء الذين سيتم تفعيلهم: ",
    upToMembers: "(حتى {$INTERPOLATION} أعضاء)",
    dontHavePayment: "ليس لديك أي وسيلة دفع لتفعيل حسابك",
    activateSubCharge: "عند تفعيل اشتراكك ، سيتم تحصيل ",
    hour: "س",
    oneHour: "ساعة واحدة",
    twoHours: "ساعتين",
    moreHours: "ساعات",
    moreThan11Hours: "ساعة",
    earlyMorning: "الصباح الباكر",
    morning: "الصباح",
    afternoon: "بعد الظهر",
    evening: "المساء",
    am1: "12:00ص - 4:59ص",
    am2: "5:00م - 11:59ص",
    pm1: "12:00م - 5:59م",
    pm2: "6:00م - 11:59م",
    departureTime: "وقت المغادرة",
    upTo: "حتى",
    forEachMonth: "شهر",
    forEachYear: "شهر",
    flightInfo: "عند التعطيل، تحتاج جميع الرحلات للموافقة",
    hotelInfo: "عند التعطيل، تحتاج جميع حجوزات الفنادق للموافقة",
    membersPolicyInfo: "ستحل سياسة سفر العضو محل سياسة الفريق.",
    approvalAlwaysRequiredHotel: "الموافقة مطلوبة لحجز أي فندق.",
    approvalAlwaysRequiredFlight: "الموافقة مطلوبة لحجز أي رحلة.",
    removePolicyMsg3: "،لن يكون للأعضاء المرتبطين بهذه السياسة أي قيود سفر.",
    removePolicy3: "بحذف سياسة ",
    removeTeam5: "،لن يتم تعيين الأعضاء في أي سياسة سفر.",
    companyInfo: "بيانات الشركة",
    offices: "المكاتب ",
    addAnyCompAddresses: "اضافة عناوين فروع الشركة",
    AreSureDeleteAddress:
      "هل أنت متأكد أنك تريد حذف {$INTERPOLATION}؟ لا يمكن التراجع عن هذا الإجراء!",
    officeBranches: "فروع المكتب",
    deleteAddress: "حذف عنوان",
    officeAddress: "عنوان المكتب",
    officeNameRequired: "اسم المكتب مطلوب",
    address1Required: "العنوان 1 مطلوب",
    MainOfficeAddress: "عنوان المكتب الرئيسي",
    officeBranchesAdd: "إضافة فرع المكتب",
    hotelBookingAllowed: "يسمح بالحجز في فندق",
    flightBookingAllowed: "يسمح بحجز رحلة",
    tripsWithinPolicy: "الرحلات ضمن حد السياسة",
    tripsOutOfPolicy: "الرحلات خارج حد السياسة",
    userPreferences: "التفضيلات",
    preferedCurrency: "العملة",
    citiesSearch: "الوجهات",
    airportsSearch: "المطارات",
    hotelsSearch: "الفنادق",
    guestSelect: "ضيف",
    dashboard: "الرئيسية",
    generateReport: "إنشاء تقرير",
    datesRquired: "التواريخ مطلوبة",
    selectDates: "إختر التواريخ",
    maxDateRange: "الفترة يجب أن لا تتجاوز سنة واحدة",
    costReporting: "تقرير التكلفة",
    costReportingRequired: "تقرير التكلفة مطلوب",
    costCenter: "مركز التكلفة",
    labels: "العلامات",
    labelsRequired: "العلامات مطلوبة",
    selectCostCenter: "إختر مركز التكلفة",
    selectLabel: "إختر العلامات",
    downloadCSV: "تنزيل الملف",
    bookings: "الحجوزات",
    spends: "الإنفاقات",
    refunds: "المستردات",
    totals: "إجمالي المبلغ",
    filterOptional: "فلتر (اختياري)",
    filterByLabels: "فلتر حسب التصنيفات",
    filterByCostCenter: "فلتر حسب مركز التكلفة",
    filterByTraveler: "فلتر حسب المسافر",
    bookingFee: "رسوم الحجز",
    limitRequired: "الحد الأعلى مطلوب",
    currencyRequired: "العملة مطلوبة",
    actualChargedAmount: "المبلغ الفعلي المقتطع",
    actualRefundAmount: "المبلغ الفعلي المسترد",
    startDate: 'تاريخ البداية',
    endDate: 'تاريخ الإنتهاء',
    maximumActiveMembers: 'الحد الأعلى للأعضاء الفعَّالين',
    subDetailsContact1: 'تفاصيل اشتراكك مدرجة أدناه.',
    subDetailsContact2: 'للمزيد من المعلومات أو الاستفسارات، يرجى التواصل معنا عبر البريد الإلكتروني أو الدردشة، أو التواصل مع مدير حسابك.',
    numberOfMembers: 'عدد الأعضاء',
    costManagement: 'إدارة التكاليف',
    costManagementDesc: 'لفهم تكلفة سفرك بشكل أفضل',
    costCenters: 'مراكز التكلفة',
    costCenterMandotary: 'مركز التكلفة إلزامي لجميع الحجوزات',
    archive: 'أرشفة',
    addCenter: 'إضافة مركز',
    addCostCenter: 'إضافة مركز تكلفة',
    costCentersNote: 'يمكن أن تمثل مراكز التكلفة كيانات متنوعة، مثل الفرق، الأقسام، العملاء، المشاريع، والمزيد.',
    labelsNote: 'يمكن أن تغطي العلامات مجموعة واسعة من الفئات، بما في ذلك الانطلاقات الداخلية، الفعاليات، المشاريع، والمزيد.',
    labelMandatory: 'العلامة إلزامية لجميع الحجوزات',
    addLabel: 'إضافة علامات',
    enterLabelName: 'ادخل اسم العلامة',
    enterCenterName: 'ادخل اسم المركز',
    enterCostName: 'ادخل اسم التكلفة',
    enterDescription: 'ادخل الوصف',
    noFilteredData: 'لا توجد بيانات مفلترة متاحة',
    costCentersEmtpy: 'أنشئ مراكز تكلفة لتعزيز فهمك لنفقات السفر. يمكن أن تمثل مراكز التكلفة كيانات متنوعة، مثل الفرق، الأقسام، العملاء، المشاريع، والمزيد.',
    editPolicy: 'تعديل السياسة',
    labelEmpty: 'استخدم التصنيفات لتصنيف نفقات السفر بشكل فعال وتعزيز قدرات التقرير. يمكن أن تغطي التصنيفات مجموعة واسعة من الفئات، بما في ذلك الانطلاقات الداخلية، الفعاليات، المشاريع، والمزيد.',
    noDataFound: 'لا يوجد بيانات',
    labelDescription: 'وصف العلامة',
    centerDescription: 'وصف مركز التكلفة',
    labelName: 'اسم العلامة',
    centerName: 'اسم مركز التكلفة',
    fieldRequired: 'هذا الحقل مطلوب',
    createCenter: 'إنشاء المركز',
    createLabel: 'إنشاء العلامة',
    updateCenter: 'تحديث المركز',
    updateLabel: 'تحديث العلامة',
    labelArchiveMsg: 'هل أنت متأكدة من أنك تريد أرشفة العلامة؟',
    labelActivateMsg: 'هل أنت متأكدة من أنك تريد تفعيل العلامة؟',
    costArchiveMsg: 'هل أنت متأكدة من أنك تريد أرشفة التكلفة؟',
    costActivateMsg: 'هل أنت متأكدة من أنك تريد تفعيل التكلفة؟',
    costActivation: 'تفعيل التكلفة',
    labelActivation: 'تفعيل العلامة',
    maxPrice: 'الحد الأعلى للسعر:',
    cardNameRequired: "اسم صاحب البطاقة مطلوب.",
    cardNumberRequired: "رقم البطاقة مطلوب.",
    expiryMonthRequired: "شهر انتهاء الصلاحية مطلوب.",
    expiryYearRequired: "سنة انتهاء الصلاحية مطلوبة.",
    securityCode: "رمز الحماية (CVV)",
    securityCodeRequired: "مطلوب رمز الحماية.",
    expiryYear: "سنة انتهاء الصلاحية (YY)",
    expiryMonth: "شهر انتهاء الصلاحية (MM)",
    cardNumber: "صاحب البطاقة",
    spaceNotPermitted: "المسافة غير مسموح بها.",
    approver: 'المُصادِق',
    noPaymentMethod: "ليس لديك طريقة للدفع",
    errorPayment: "حدث خطأ أثناء معالجة الدفع",
    amazonUnexpectedResponse: "خطأ في إضافة وسيلة الدفع",
    selectPaymentCard: "اختر بطاقة الدفع",
    errorCodeFromUrl: "حدث خطأ اثناء عملية الدفع ",
    maxStay: "الحد الأقصى للإقامة هو 30 ليلة لكل حجز",
    selectAddress: "المكتب الذي يتبع له الفريق",
    office: "المكتب"
  },
};
if (
  localStorage.getItem("locale") &&
  localStorage.getItem("locale").includes("ar")
) {
  loadTranslations(translationEl.translations);
}
